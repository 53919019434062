import { EventEmitter, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { WebmsgModel } from '@shared/models/webmsg-model';
import { AppInfoService } from '@shared/services/common/app-info.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { DataRefreshModel } from '@shared/models/datarefresh-model';
import { ReOpenFormModel, ReOpenMenuModel } from '@shared/models/reopenform-model';
import { OpenLoadingModel } from '@shared/models/openloading-model';
import { ChatMessageModel } from '@shared/models/chatmessage-model';
import { KanbanHubModel } from '@shared/models/kanban-hub-model';
import { ProfileHubModel } from '@shared/models/profile-hub-model';
import { JoblistChatHubModel, NotificationHubModel } from '@shared/models/notification-hub-model';
import { EventEmitterService } from '../common';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class WebMessageService {
    messageReceived = new EventEmitter<WebmsgModel>();
    changeModule = new EventEmitter<DataRefreshModel>();
    jobListChat = new EventEmitter<JoblistChatHubModel>();
    reOpenForm = new EventEmitter<ReOpenFormModel>();
    reOpenMenu = new EventEmitter<ReOpenMenuModel>();
    chatMessageGetAll = new EventEmitter<ChatMessageModel>();
    openLoading = new EventEmitter<OpenLoadingModel>();
    projectManagementEvent = new EventEmitter<KanbanHubModel>();
    profileManagementEvent = new EventEmitter<ProfileHubModel>();
    notificationManagementEvent = new EventEmitter<NotificationHubModel>();
    chatUserStatus = new EventEmitter<number>();

    connectionEstablished = new EventEmitter<Boolean>();
    private connectionIsEstablished = false;
    private _hubConnection: HubConnection;
    private _unsubscribeAll: Subject<void>;
    isLoggedIn = false;


    constructor(public appInfo: AppInfoService, private http: HttpClient, public eventEmitter: EventEmitterService) {
        /*this.createConnection();
        this.registerOnServerEvents();
        this.JobListChatChange();
        this.updateModule();
        this.reOpenFormEvent();
        this.reOpenMenuEvent();
        this.chatMessageGetAllEvent();
        this.openLoadingEvent();
        this.chatUserStatusEvent();
        this.startConnection();
        this.projectManagementChangeEvent();
        this.profileManagementChangeEvent();
        this.notificationManagementChangeEvent();


    this.authService._isLoggedIn.subscribe(data => {
        console.log(data)
        debugger;
        if (data) {
            this.isLoggedIn = true;
            this.createConnection();
            this.startConnection();
            this.registerOnServerEvents();
            this.JobListChatChange();
            this.updateModule();
            this.reOpenFormEvent();
            this.reOpenMenuEvent();
            this.chatMessageGetAllEvent();
            this.openLoadingEvent();
            this.chatUserStatusEvent();
            this.projectManagementChangeEvent();
            this.profileManagementChangeEvent();
            this.notificationManagementChangeEvent();

        } else {
            this.isLoggedIn = false;
            if (this._hubConnection) {

                this._hubConnection.off("NotificationManagementChange");
                this._hubConnection.stop();
                this._hubConnection = undefined;
            }
          
        }
    })*/

    }

    sendMessage(message: WebmsgModel) {
        if (!!this._hubConnection) {
            this._hubConnection.invoke('NewWebMessage', message);
        }
    }

    saveMessageInfo(message: WebmsgModel): Observable<boolean> {
        let params = new HttpParams().set("id", message.id.toString())/*.set("userId", message.user_id.toString()).set("isRead", message.is_read.toString())*/;

        return this.http.get<boolean>(this.appInfo.appUrl + "api/generic/saveMessageInfo", { params: params });
    }


    sendMessageService(message: WebmsgModel): Observable<boolean> {
        debugger
        let params = new HttpParams().set("user_id", message.user_id.toString()).set("msg_show_type", message.msg_show_type.toString()).set("message_type", message.message_type.toString())
            .set("message", message.message.toString()).set("message_title", message.message_title.toString()).set("is_read", message.is_read.toString()).set("id", message.id.toString());

        return this.http.get<boolean>(this.appInfo.appUrl + "api/generic/sendwebmessage", { params: params });
    }

    startAuthConnection() {
        this.isLoggedIn = true;
        this.createConnection();
        this.startConnection();
        this.registerOnServerEvents();
        this.JobListChatChange();
        this.updateModule();
        this.reOpenFormEvent();
        this.reOpenMenuEvent();
        this.chatMessageGetAllEvent();
        this.openLoadingEvent();
        this.chatUserStatusEvent();
        this.projectManagementChangeEvent();
        this.profileManagementChangeEvent();
        this.notificationManagementChangeEvent();
    }

    stopConnection() {
        this.isLoggedIn = false;
        if (this._hubConnection) {
            this._hubConnection.off("NotificationManagementChange");
            this._hubConnection.stop();
            this._hubConnection = undefined;
        }
    }

    private createConnection() {

        this._hubConnection = new HubConnectionBuilder()
            .withUrl(this.appInfo.appUrl + 'RiverHub')
            .build();

        this._hubConnection.onclose(() => {
            if (this.isLoggedIn) {
                let _this = this;
                setTimeout(function () {
                    _this.startConnection();
                }, 5000)
            }



        });


    }

    private startConnection(): void {


        this._hubConnection
            .start()
            .then(() => {
                this.connectionIsEstablished = true;
                this.connectionEstablished.emit(true);

            })
            .catch(err => {
                if (this.isLoggedIn) {
                    let _this = this;
                    setTimeout(function () {
                        _this.startConnection();
                    }, 5000);
                }




            })

    }




    private registerOnServerEvents(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('MessageReceived', (data: any) => {
                if (typeof this._hubConnection != "undefined") {
                    this.messageReceived.emit(data);
                }
            });
        }

    }

    private updateModule(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ChangeModule', (data: any) => {
                if (typeof this._hubConnection != "undefined") {
                    this.changeModule.emit(data);
                }
            });
        }
    }

    private reOpenFormEvent(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ReOpenForm', (data: any) => {
                if (typeof this._hubConnection != "undefined") {
                    this.reOpenForm.emit(data);
                }
            });
        }

    }

    private reOpenMenuEvent(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ReOpenMenu', (data: any) => {

                if (typeof this._hubConnection != "undefined") {
                    this.reOpenMenu.emit(data);
                }
            });
        }

    }

    private chatMessageGetAllEvent(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('SendChatMessage', (data: any) => {
                if (typeof this._hubConnection != "undefined") {
                    this.chatMessageGetAll.emit(data);
                }
            });
        }

    }
    private openLoadingEvent(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('OpenLoading', (data: any) => {
                if (typeof this._hubConnection != "undefined") {
                    this.openLoading.emit(data);
                }
            });
        }

    }
    private chatUserStatusEvent(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ChatUserStatus', (data: any) => {
                if (typeof this._hubConnection != "undefined") {
                    this.chatUserStatus.emit(data);
                }
            });

        }

    }

    private projectManagementChangeEvent(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ProjectManagementChange', (data: any) => {
                if (typeof this._hubConnection != "undefined") {
                    this.projectManagementEvent.emit(data);
                }
            });
        }

    }
    private profileManagementChangeEvent(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ProfileManagementChange', (data: any) => {
                if (typeof this._hubConnection != "undefined") {
                    this.profileManagementEvent.emit(data);
                }
            });
        }

    }
    private notificationManagementChangeEvent(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('NotificationManagementChange', (data: any) => {
                debugger;
                if (typeof this._hubConnection != "undefined") {
                    this.notificationManagementEvent.emit(data);
                }

            })
        }

    }
    private JobListChatChange(): void {
        debugger
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('JobListChat', (data: any) => {
                if (typeof this._hubConnection != "undefined") {
                    this.jobListChat.emit(data);
                }
            })
        }

    }

    sendChatUserId() {
        if (typeof this._hubConnection != "undefined" && this._hubConnection.state == 1) {
            this._hubConnection.invoke('ChatUserStatus', this.appInfo.currentUser.id);
        }

    }
}  
