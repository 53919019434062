import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AppInfoService } from '@shared/services/common/app-info.service';
import { MenuNode } from '@shared/models/menu-node-model';
import { FormModel, FormSqlGuidModel } from '@shared/models/form-model';

@Injectable({
    providedIn: 'root'
})
export class FormService {

    constructor(public appInfo: AppInfoService, private http: HttpClient) {

    }
    formExport(formData): Observable<any> { 
        return this.http.post<any>(this.appInfo.appUrl + "api/forms/formexport", JSON.stringify(formData), this.appInfo.httpOptions);
    }
    formFileControl(formData): Observable<string> {
        return this.http.post<string>(this.appInfo.appUrl + "api/forms/formfilecontrol", JSON.stringify(formData), this.appInfo.httpOptions);
    }
    getAllForms() {
        return this.http.get(this.appInfo.appUrl + "api/forms/getall", this.appInfo.httpOptions);
    }

    getGroupedForms(): Observable<FormModel[]> {
        
        return this.http.get<FormModel[]>(this.appInfo.appUrl + "api/forms/getformsasgroup", this.appInfo.httpOptions);
    }

    getSingleForm(formId: string): Observable<FormModel> {
        let params = new HttpParams().set("id", formId.toString());
        
        return this.http.get<FormModel>(this.appInfo.appUrl + "api/forms/getsingle", { params: params});
    }

    getMenuFormId(_guid: string): Observable<any> {
        let params = new HttpParams().set("guid", _guid);
        
        return this.http.get<any>(this.appInfo.appUrl + "api/forms/getmenuformid", { params: params });
    }

    geFormField(formId: string): Observable<string> {
        let params = new HttpParams().set("id", formId.toString());
        
        return this.http.get<string>(this.appInfo.appUrl + "api/forms/getfield", { params: params });
    }


    saveForm(formData): Observable<FormModel> {
        return this.http.post<FormModel>(this.appInfo.appUrl + "api/forms/save", JSON.stringify(formData), this.appInfo.httpOptions);
    }

    updateForm(formData, userId): Observable<FormModel> {
        console.log(formData)
        return this.http.post<FormModel>(this.appInfo.appUrl + "api/forms/update?userId=" + userId, JSON.stringify(formData), this.appInfo.httpOptions);
    }

    updateFormFields(id, formData) {
        return this.http.post(this.appInfo.appUrl + "api/forms/updatefields", { id: id, data: JSON.stringify(formData) }, this.appInfo.httpOptions);
    }

    deleteForm(formId: number): Observable<boolean> {
        //let params = new HttpParams().set("id", formId.toString()); 
        return this.http.get<boolean>(this.appInfo.appUrl + "api/form/delete?id=" + formId, this.appInfo.httpOptions);
    }

    getFormFilesData(objArray): Observable<any> {
        return this.http.post<any>(this.appInfo.appUrl + "api/forms/getmenufiledata", JSON.stringify(objArray), this.appInfo.httpOptions);
    }

    saveMenuForm(formData, fileIds = []): Observable<MenuNode> {

        let fileIdsStr = "";

        if (fileIds.length > 0) {
            fileIdsStr += "&fileIds=" + fileIds;
        }

        //console.log(this.appInfo.appUrl + "api/forms/savemenuform?isArray=false" + fileIdsStr)
        return this.http.post<MenuNode>(this.appInfo.appUrl + "api/forms/savemenuform?isArray=false" + fileIdsStr, JSON.stringify(formData), this.appInfo.httpOptions);
    }

    updateJustMenuData(formJustDataArray): Observable<MenuNode> { 
        return this.http.post<MenuNode>(this.appInfo.appUrl + "api/forms/updatejustmenudata", JSON.stringify(formJustDataArray), this.appInfo.httpOptions);
    }

    checkMenuForm(formData): Observable<MenuNode> {

        return this.http.post<MenuNode>(this.appInfo.appUrl + "api/forms/savemenuform?isArray=true", JSON.stringify(formData), this.appInfo.httpOptions);
    }

    deleteMenuForm(formData, indexRow): Observable<boolean> {
        return this.http.post<boolean>(this.appInfo.appUrl + "api/forms/deletemenuform", JSON.stringify(formData), this.appInfo.httpOptions);
    }

    getMenuForm(formId: string, nodeKey: string, key: number, userId: string) {
        let params = new HttpParams().set("formId", formId).set("nodeKey", nodeKey).set("rowId", key.toString()).set("userId", userId);
        
        return this.http.get(this.appInfo.appUrl + "api/forms/getmenuform", { params: params });
    }

    errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ` + error.status + `\nMessage: ` + error.message;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }

    saveMenuFilesData(objArray, processFormId, deleteFileIds = []): Observable<any> {

        let deleteFileIdsStr = "";
        if (deleteFileIds.length > 0)
            deleteFileIdsStr = "&deleteFileIds=" + deleteFileIds;
        return this.http.post<any>(this.appInfo.appUrl + "api/forms/savemenufiledata?processFormId=" + processFormId + deleteFileIdsStr, JSON.stringify(objArray), this.appInfo.httpOptions);
    }

    getMenuFilesData(objArray): Observable<any> {
        return this.http.post<any>(this.appInfo.appUrl + "api/forms/getmenufiledata", JSON.stringify(objArray), this.appInfo.httpOptions);
    }


    getFormSqlGuidList(formId: number): Observable<string> {
        let params = new HttpParams().set("formId", formId.toString());
        return this.http.get<string>(this.appInfo.appUrl + "api/forms/getFormSqlGuidList", { params: params });
    }

    saveFormSqlGuid(formGuidSqls): Observable<FormSqlGuidModel[]> {
        return this.http.post<FormSqlGuidModel[]>(this.appInfo.appUrl + "api/forms/saveFormSqlGuid", JSON.stringify(formGuidSqls), this.appInfo.httpOptions);
    }

    //getMenuFilesDataMultiple(objArray): Observable<any> {
    //    return this.http.post<any>(this.appInfo.appUrl + "api/forms/getmenufiledatamultiple", JSON.stringify(objArray), this.appInfo.httpOptions);
    //}

    checkFilePath(basePath: string, url: string): Observable<boolean> {
        const fullUrl = url + basePath;

        return this.http.post<boolean>(this.appInfo.appUrl + "api/forms/getCheckPath", JSON.stringify(fullUrl), this.appInfo.httpOptions);
    }

}
