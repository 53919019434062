export const locale = {

    lang: 'GORGOR',
  
    data: {
  
        loginButton: "შესვლა",
        userName: "სახელი",
        pass: "პაროლი",
        jobListMessageArea:"სამუშაო სიის შეტყობინებების არეალი",
        remember: "დამიმახსოვრე",
        forgotPass: "პაროლის ვისის დავიწყება",
        modules: "მოდულები",
        generic: "ზოგადი",
        search: "ძებნა",
        generateLink:"ბმულის გენერირება",
        logout: "გამოსვლა",
        waitingJob: "ჩემი მიმდინარე საქმიანობა",
        myStarted: "მე დავიწყებ",
        myIncluded: "ჩემი ჩაიჭებ",
        fileLink: "ფაილის ბმული",
        givePasswordToTheLink: "მიეცით პაროლი ბმულზე",
        giveTheLinkAnExpirationDate: "მიეცით ლინკს ვადის გასვლის თარიღი",
        myCompleted: "დასრულებული საქმეები",
        jobList: "საქმიების სია",
        formNo: "ფორმის ნომერი",
        status: "სტატუსი",
        starter: "დაწყებილი",
        assigned: "მინიჭებული",
        timeoutDate: "ვადაგასვლის თარ.",
        startDate: "დაწყების თარ.",
        processing: "დამუშავება",
        you: "თქვენ",
        history: "ისტორია",
        description: "აღწერა",
        date: "თარიღი",
        growShrink: "გადიდე/შეკლება",
        close: "დახურვა",
        gridReset: "სიახლეების ჩამოშლა",
        columnSelect: "სვეტის შერჩევა",
        jobName: "საქმის სახელი",
        keyword: "საკვალიფიკაციო სიტყვა",
        completion: "დასრულება",
        all: "ყველა",
        completedDate: "დასრულების თარ.",
        completions: "დასრულებული",
        ongoing: "განმეორებითი",
        formName: "ფორმის სახელი",
        sunday: "კვირა",
        monday: "ორშაბათი",
        tuesday: "სამშაბათი",
        wednesday: "ოთხშაბათი",
        thursday: "ხუთშაბათი",
        friday: "პარასკევი",
        saturday: "შაბათი",
        pdfExport: "PDF ექსპორტი",
        sendPassword: "გამოგზავნე ჩემი პაროლი",
        assignedDate: "მინიჭების თარ.",
        events: "ღონისძიებები",
        new: "ახალი",
        save: "შენახვა",
        formula: "ფორმულა",
        selectDataSource: "მონაცემთა წყაროს არჩევა",
        requiredField: "სავალდებულო ველი",
        suffix: "სუფიქსი",
        valueColumn: "მნიშვნელობების სვეტი",
        showLength: "ცხრილში ამოცანების რაოდენობის ჩვენება",
        clearButton: "ღია ღილაკი",
        allCaps: "ყველა დიდი ასო",
        autoAsc: "ავტო ასენდირება",
        width: "სიგანე",
        length: "სიგანე",
        functions: "ფუნქციები",
        selectElement: "ელემენტის არჩევა",
        selectColumn: "სვეტის არჩევა",
        edit: "რედაქტირება",
        changeEventActions: "ღონისძიების ქმედებების შეცვლა",
        barcode: "შტრიხკოდი",
        systemInfo: "სისტემის ინფორმაცია",
        prefix: "პრეფიქსი",
        max: "მაქსიმალური",
        showMinMaxInfo: "მინიმალური და მაქსიმალური ინფორმაციის ჩვენება",
        selectUser: "მომხმარებლის არჩევა",
        spinButton: "დაბალა ღილაკი",
        numberIncDecButton: "რიცხვის გაზრდა-შეკლების ღილაკი",
        decimal: "დეციმალური რიცხვი",
  
        numeral: "რიცხვი",
        wrongFormat: "შეამოწმეთ არასწორი ფორმატი",
        height: "სიმაღლე",
        microphone: "მიკროფონი",
        notifyDataReset: "მონიშნული მონაცემების გასუფთავება",
        data: "მონაცემები",
        dynamicData: "დინამიური მონაცემები",
        manualData: "ხელით მონაცემები",
        selectDataGrid: "მონაცემთა რეგისტრის არჩევა",
        overrideUserChange: "გამიწევეთ იუზერის ცვლილებები",
        textColumn: "ტექსტის სვეტი",
        makeInitialValue: "ახალი საწყისი მნიშვნელობა",
        undo: "გაუქმება",
        copy: "კოპირება",
        preview: "გადახედვა",
        delete: "წაშლა",
        cancel: "გაუქმება",
        languageSettings: "ენის პარამეტრები",
        messageSqlLinkDeleted: "რადგან თქვენ მანამ მითითებული მონაცემები ხელით დაემატა, SQL სკრიპტი და ლინკის მისამართი წაიშლება! განაგრძოთ?",
        permissions: "უფლებები",
        objectid: "ობიექტის ID",
        fontSize: "შრიფტის ზომა",
        fontWidth: "ტექსტის სიგანე",
        fontStyle: "შრიფტის სტილი",
        underline: "ქვეყანა",
        textColor: "ტექსტის ფერი",
        visibility: "ხილვადობა",
        textAlignment: "ტექსტის სწორება",
        settings: "პარამეტრები",
        messageUnsaved: "გსურთ გასათიშობი დაბრუნება ცვლილებების შესანახად?",
        warning: "გაფრთხილება!",
        userAuthorizations: "მომხმარებლის ავტორიზაციები",
        formPreferences: "ფორმის პრეფერენსები",
        formOpeningevent: "ფორმის გახსნის ღონისძიება",
        chooseConnectionAddress: "არჩევა კავშირის მისამართი",
        selectPriorty: "პრიორიტეტის არჩევა",
        loadingEditor: "ტექსტის რედაქტორის ჩატვირთვა...",
        showAddButton: "დაამატე სვეტის ღილაკი",
        update: "განახლება",
        connectionID: "კავშირის ID",
        formPreview: "ფორმის წინასწარი ხედი",
        nameDisplayedform: "ფორმის სახელი, რომლის ჩვენება შეიძლება",
        formNamevariable: "ფორმის სახელის ცვლადი",
        category: "კატეგორია",
        formColor: "ფორმის ფერი",
        generalForm: "ზოგადი ფორმა",
        saveForm: "ფორმის შენახვა",
        messageNeworupdate: "შეიცავს წარმოებულ ფორმაში შეყვანილი ცვლილებებს, რომლებიც წაშლილი იქნება. გსურთ განაგრძოთ?",
        messageDelete: "გსურთ ფორმის წაშლა?",
        toastDeleted: "ფორმა წაიშალა",
        toastError: "უკავშირის მიმართულებისას შეცდომა მოხდა",
        alertDifferentID: "ამ ID-ს მიმართულება უკვე გამოყენებულია სხვა ადგილებში, გთხოვთ, მიუთითოთ სხვა ID.",
        confirmChanges: "ცვლილებების დადასტურება",
        toastUserpermission: "მომხმარებელს არ აქვს ფორმის დიზაინის უფლებები.",
        toastCodeeditor: "კოდრედაქტორი უკვე არსებობს",
        messageDeletesure: "ელემენტები ამ კომპონენტში წაიშალება",
        messageSure: "გსურთ წაშლა?",
        successful: "წარმოებული",
        error: "შეცდომა",
  
        toastFormupdate: "ფორმა განახლდა",
        toastMistake: "სამწუხაროდ, შეცდომა მოხდა დამუშავების პროცესში",
        toastSaved: "შენახულია",
        toastCopied: "კოპირებულია",
        toastEmptyFormMessage: "ჯერ არც ერთი ფორმის ელემენტი დაემატა",
        toastGiveName: "გთხოვთ, მიუთითეთ თქვენი ფორმის სახელი",
        toastGiveVariableName: "გთხოვთ, მიუთითეთ სახელი ფორმის სახელის ცვლადზე",
        toastVariableNameNumberWarning: "ცვლადის სახელი ვერ იწყება ციფრით",
        formCopy: "ფორმის კოპირება",
        messageButtonDelete: "გსურთ ღილაკის წაშლა?",
        noDataFound: "მონაცემები ვერ მოიძებნა",
        language: "ენა",
        filterDatagrid: "მონაცემთა რეგისტრის ფილტრინგი",
        addManualData: "ხელით დაამატეთ მონაცემები ადგილზე",
        dateFormat: "თარიღის ფორმატი",
        messageSqlLinkDelete: "რადგან თქვენ ხელით დაემატეთ მონაცემები, SQL სკრიპტი და ლინკის მისამართი წაიშლება! გსურთ განაგრძოთ?",
        timeOption: "საათის პარამეტრები",
        defaultTodaysDate: "განსაცალკევებელი დღესვის თარიღი",
        dateRange: "თარიღის შუალედი",
        minToday: "მინიმუმი",
  
        maxToday: "დღესვის მაქსიმუმი",
        dateRangeDay: "თარიღის შუალედი (დღე)",
        enterDateRangeValue: "შეიყვარეთ თარიღის შუალედის მნიშვნელობა",
        imageIdColumn: "ID სვეტი",
        statusSettings: "სტატუსის პარამეტრები",
        active: "აქტიური",
        passive: "პასიური",
        enable: "ჩართვა",
        disable: "გამორთვა",
        visible: "ხილვადი",
        invisible: "უხილავი",
        items: "ელემენტები",
        showItems: "ელემენტების ჩვენება",
        array: "მასივი",
        letLabelAppear: "ჩაასვი ეტიკეტი",
        add: "დამატება",
        horizontal: "ჰორიზონტალური",
        vertical: "ვერტიკალური",
        text: "ტექსტი",
        alertFillRequiredFields: "გთხოვთ, შეავსეთ სავალდებულო ველები",
        alertSignatureField: "ხელმოწერილი ველი ვერ იყოფა ცარიელი",
        messageApproveTransaction: "გსურთ ტრანზაქციის დასტურება?",
        button: "ღილაკი",
        reasonforRefusal: "უარყოფაზე მიზეზი",
        enterReasonRejection: "შეიყვარეთ უარყოფის მიზეზი",
  
        reject: "უარყოფა",
        addDeleteButton: "ღილაკის დამატება/წაშლა",
        showBorder: "ჩარიტების გამოქვეყნება",
        buttons: "ღილაკები",
        clean: "გასუფთავება",
        buttonSettings: "ღილაკის პარამეტრები",
        saveLocation: "შენახვის ადგილი",
        paymentButton: "გადახდის ღილაკი",
        paymentProvider: "გადახდის მუშაობა",
        price: "ფასი",
        numberOfInstallments: "განვადების რაოდენობა",
        descriptionField: "აღწერის ველი",
        current: "მიმდინარე",
        currentForm: "მიმდინარე ფორმა",
        name: "სახელი",
        surname: "გვარი",
        country: "ქვეყანა",
        city: "ქალაქი",
        address: "მისამართი",
        authorizations: "ავტორიზაციები",
        users: "მომხმარებლები",
        user: "მომხმარებელი",
        givePermission: "ავტორიზაციის მინიჭება",
        groups: "ჯგუფები",
        group: "ჯგუფი",
        saveandSend: "შენახვა და გაგზავნა",
        approve: "დამტკიცება",
        sendBack: "უკავშირდება უკავშირდება",
        deslineAndEnd: "უარყოფა და დასრულება",
        complete: "დასრულებული",
        blue: "ლურჯი",
        gray: "მოწყალე",
        green: "მწვანე",
        red: "წითელი",
        left: "მარცხნივ",
        right: "მარჯვენა",
        color: "ფერი",
  
        symbol: "სიმბოლო",
        position: "პოზიცია",
        action: "ქმედება",
        total: "ჯამი",
        filePath: "ფაილის გზა",
        fileName: "ფაილის სახელი",
        fileUpload: "ფაილის ატვირთვა",
        choose: "არჩევა",
        updateData: "მონაცემების განახლება",
        show: "ჩვენება",
        columns: "სვეტები",
        changeEvent: "ივენთის შეცვლა",
        columnSettings: "სვეტის პარამეტრები",
        columnName: "სვეტის სახელი",
        deleteColumn: "სვეტის წაშლა",
        columnSqlScripts: "სვეტის SQL სკრიპტები",
        imageNameColumn: "ფაილის სახელის სვეტი",
        columnLanguageSetting: "სვეტის ენის პარამეტრები",
        dataSource: "მონაცემთა წყარო",
        filter: "ფილტრი",
        reset: "გადატვირთვა",
        column: "სვეტი",
        image: "სურათი",
        searchLocation: "ძებნის ადგილი...",
        draggable: "გადამატებადი",
        usersCanChange: "მომხმარებელებმა შეიძლება შეცვალოს",
        multiMarker: "მრავლებრივი მარკერი",
        markerLimit: "მარკერების ლიმიტი (0: ლიმიტი არ არის)",
        meetingType: "შეხვედრის ტიპი",
        meetingName: "შეხვედრის სახელი",
        loadingFiles: "ფაილების ჩატვირთვა...",
        fileSizeExceeded: "ფაილის ზომა აღემატება",
        filesLoaded: "ფაილები ჩატვირთულია",
        condition: "მდა",
        value: "მნიშვნელობა",
        connectionAddress: "კავშირის მისამართი",
        imageTypeColumn: "ფაილის ტიპის სვეტი",
        visibleColumn: "ხილულობის სვეტი",
        selectionMode: "შერჩევის რეჟიმი",
        selectMode: "არჩევის რეჟიმი",
        datagridFiltering: "Datagrid ფილტრაცია",
        imageDataColumn: "ფაილის სვეტი",
        selectDataColumn: "Datagrid სვეტის არჩევა",
        dataFormat: "მონაცემთა ფორმატი",
        photoPreview: "სურათის წინასწარი",
        videoPreview: "ვიდეოს წინასწარი",
        displayPhoto: "სურათის ჩვენება",
        displayVideo: "ვიდეოს ჩვენება",
        imageColumn: "სურათის სვეტი",
        videoColumn: "ვიდეოს სვეტი",
        columnNameAppear: "გამოჩნდეს სვეტის სახელი",
  
        selected: "არჩეული",
        inStock: "საწყობში",
        messageSave: "გსურთ შენახვა?",
        gallery: "გალერეა",
        dollar: "დოლარი",
        sterling: "ფუნტი",
        underPhoto: "ფოტოს ქვეშ",
        topPhoto: "ფოტოს ზემოთ",
        byCategory: "კატეგორიების მიხედვით",
        dashboardShowHide: "დაფარვა/ჩვენება დაშაბათში",
        showDetail: "დეტალების ნახვა",
        hideDetail: "დეტალების დამალვა",
        decrease: "შემცირება",
        increase: "ზრდა",
        watch: "ყურადღება",
        watchMovie: "კინოს ნახვა",
        noRecordsFound: "ჩანაწერები არ არის!",
        appearance: "გარემონტირება",
        numberofColumns: "სვეტების რაოდენობა",
        pagingRecords: "გვერდების ჩანაწერები",
        detailLocation: "დეტალური ადგილი",
        hideImages: "სურათების დამალვა",
        quantityCategoryDisable: "კატეგორიის გათიშვა როდესაც რაოდენობა ზრდადება",
        general: "ზოგადი",
        idColumn: "ID სვეტი",
        availableQuantity: "ხელმისაწვდომი რაოდენობა",
        categoryColumn: "კატეგორიის სვეტი",
        photoColumn: "ფოტოს სვეტი",
        titleColumn: "სათაურის სვეტი",
        priceColumn: "ფასის სვეტი",
        updatablePrice: "განახლებადი ფასი",
        videoGallery: "ვიდეო გალერეა",
        uniqueComponent: "უნიკალური კომპონენტი",
        showVariant: "ვარიანტის ჩვენება",
        transferTotalAmount: "საერთო თანხის გადატანა",
        title: "სათაური",
        hide: "დამალვა",
        detail: "დეტალი",
        priceQuery: "ფასის შეკითხვა",
        quantityQuery: "რაოდენობის შეკითხვა",
        uniqueComponentError: "უნიკალური კომპონენტის შეცდომა",
        joinColumn: "ჯოინ სვეტი",
        nameColumn: "სახელის სვეტი",
        variantScript: "ვარიანტის სკრიპტი",
        variantImages: "ვარიანტის სურათები",
        variantJoinColumn: "ვარიანტის ჯოინ სვეტი",
        showAll: "ყველას ჩვენება",
        showSelected: "არჩეულების ჩვენება",
        showByCategory: "კატეგორიის მიხედვით ჩვენება",
        showInStock: "რაოდენობის ჩვენება საწყობში",
        notYetAdded: "ჯერ არ არის დამატებული",
        added: "დამატებული",
        startingDate: "დასაწყისი თარიღი",
        endDate: "დასასრული თარიღი",
        themeFeatures: "თემის ფუნქციები",
        menu: "მენიუ",
        selectMenu: "მენიუს არჩევა",
        selectSubmenu: "ქვემენიუს არჩევა",
        selectMenuBrowser: "მენიუს ბრაუზერის არჩევა",
        nameElement: "ელემენტის სახელი",
        subject: "საგანი",
        picture: "სურათი",
        pictureElement: "სურათის ელემენტი",
        startingDateElement: "დაწყების თარიღის ელემენტი",
        endDateElement: "დასასრული თარიღის ელემენტი",
        usersCanEdit: "მომხმარებლები შეიძლება რედაქტირება",
        thisPropertyCant: "ამ თვალს არ შეიძლება გამოიყენოს თუ მონაცემები იღება SQL-იდან",
        calendarDisplay: "კალენდარის ჩვენების პარამეტრები",
        monthly: "ყოველთვიური",
        generalDisplayLunarDays: "ლუნის დღეების ჩვენება (ძველი კალენდარი)",
        agenda: "დღის წინაშე გარემო",
  
        listofWeeklyEvents: "კვირის ღონისძიებების სია",
        weekly: "კვირაში",
        weekend: "უკავშირში",
        generalDisplayWeekDays: "კონკრეტული კვირის დღეების ჩვენება",
        showWeekendDaysWhileWeekdays: "უკავშირში დღეების ჩვენება, როდესაც ჩვენება კონკრეტული კვირის დღეები",
        daily: "ყოველდღიურად",
        listofDailyEvents: "ყოველდღიური ღონისძიებების სია",
        startTime: "დაწყების დრო",
        endTime: "დასრულების დრო",
        changeDefaultColor: "საწყისი ფერების შეცვლა",
        weekdayColor: "კვირის დღეების ფერი",
        weekendColor: "უკავშირში დღეების ფერი",
        eventColor: "ღონისძიების ფერი",
        groupBySql: "SQL-ის მიხედვით ჯგუფება",
        groupColumnName: "ჯგუფის სვეტის სახელწოდება",
        assignedColumnName: "დანომრილი სვეტის სახელწოდება",
        taskPriortiyColumnName: "ამოცანის პრიორიტეტის სვეტის სახელწოდება",
        taskSubjectColumnName: "ამოცანის თემის სვეტის სახელწოდება",
        taskDetailColumnName: "ამოცანის დეტალის სვეტის სახელწოდება",
        taskQuery: "ამოცანის შეკითხვა",
        taskKeyExpression: "ამოცანის გასაღები გამოსახულება",
        taskTitleExpression: "ამოცანის სახელწოდების გამოსახულება",
        taskStartDateExpression: "ამოცანის დაწყების თარიღის გამოსახულება",
        taskEndDateName: "ამოცანის დასრულების თარიღის გასაღები სახელწოდება",
        taskParentExpression: "ამოცანის მშობელი ამოცანის გამოსახულება",
        taskProgressExpression: "ამოცანის წინაშეაბრუნებულობას გამოსახულება",
        taskColorExpression: "ამოცანის ფერის გამოსახულება",
        taskConnections: "ამოცანის კავშირები",
        diagramClear: "გსურთ დიაგრამის გაწმენდა? ეს ქმედება ვერ გამოიყენება.",
        uniqueComponentMenuCloneWarning: "გთხოვთ, კოპირების წინ შეცვალოთ უნიკალური კომპონენტი.",
        filesLoading: "ფაილები იტვირთება...",
        couldnotbeLoaded: "ვერ იტვირთა.",
        fileSizeLimit: "ფაილის ზომის ლიმიტი",
        filesUploaded: "ფაილები იტვირთა",
        filesWillDeleted: "ფაილები წაიშალება!",
        filesSureDelete: "გსურთ წაშლა?",
        fileDelete: "გსურთ ფაილის წაშლა?",
        uploadedPhotos: "",
        maxSize: "მაქსიმალური ზომა",
        center: "ცენტრში",
        size: "ზომა",
        canItBeUpdated: "შეიძლება განახლდეს შემდეგ დაყენებამდე?",
        uploadedFiles: "იტვირთავი ფაილები",
        type: "ტიპი",
        showdeleteOldData: "ძველი მონაცემების წაშლის პარამეტრები",
        videoBrowser: "თქვენი ბრაუზერი არ უხეშია ვიდეო თეგის მხარდაჭერა.",
        deleted: "წაშლილია",
        theseComponentElementsDeleted: "ეს კომპონენტის ელემენტები წაიშალა.",
        invalidTarget: "არასწორი მიზეზი",
        ridge: "ხისტა",
        dotted: "წვეულებრივი",
        dashed: "წიწილებული",
        addRowColumn: "სტრიქონის/სვეტის დამატება",
        dataPagingShow: "მონაცემების წარმოება გვერდებად",
        borderType: "ჩარჩოს ტიპი",
        maxCharacters: "სიმბოლოების მაქსიმალური რაოდენობა",
        edgeThickness: "პირების სისტემის ტოლიანი სიგრძე",
        lineSpacing: "ხაზების სივრცე",
        settingsRow: "სვეტის/სტრიქონის პარამეტრები",
        addRow: "სტრიქონის დამატება",
        deleteRow: "სტრიქონის წაშლა",
        rowPermission: "სტრიქონის ნება",
        addTabs: "ჩამოთვალის დამატება",
        deletePanel: "პანელის წაშლა",
        tabPermission: "ჩამოთვალის ნება",
        addPanel: "პანელის დამატება",
        panelPermission: "პანელის ნება",
        backgroundColor: "ფონის ფერი",
        saveAndCloseChildForm: "ახალი ფორმის გახსნა შენახვა და დახურვა ამ შვილი ფორმის გახსნის დროს",
        icon: "სიმბოლო",
        selectForm: "ფორმის არჩევა",
        fontColor: "შრიფტის ფერი",
        minCharacters: "სიმბოლოების მინიმუმი",
        generalCategory: "ზოგადი კატეგორია",
        removeRow: "გსურთ ჩანაწერის წაშლა?",
        send: "გაგზავნა",
  
        declineFinish: "უარი და დასრულება",
        finish: "დასრულება",
        noData: "არ არის მონაცემები",
        createEditMenu: "მენიუს შექმნა/რედაქტირება",
        addNewBreakdown: "ახალი ჩამონათვალის დამატება",
        addForm: "ფორმის დამატება",
        addBottomBreak: "ქვემოთ ჩასმაზე ჩასმა",
        addNode: "კვანძის დამატება",
        topCategory: "ტოპ კატეგორია",
        explanation: "აღწერა",
        browserTitle: "ბრაუზერის სათაური",
        formTitle: "ფორმის სათაური",
        addSqlColumn: "SQL სვეტის დამატება",
        formBg: "ფორმის ფონი",
        codeEditor: "კოდის რედაქტორი",
        authorityType: "ავტორიზაციის ტიპი",
        viewOnly: "მხოლოდ ნახვა",
        dark: "მუქი",
        white: "თეთრი",
        hightBlack: "შავი",
        run: "გაშვება",
        rowColor: "სტრიქონის ფერი",
        operator: "ოპერატორი",
        flowOfForm: "ფორმის რუში",
        columnWidth: "სვეტის სიგანე",
        alignLeft: "მარცხენა გასწორება",
        alignRight: "მარჯვენა გასწორება",
        alignButton: "ღილაკის გასწორება",
        imageTableNameColumn: "ცხრილის სახელი",
        uploadBg: "ფონის ატვირთვა",
        selectFile: "ფაილის არჩევა",
        dropFile: "ფაილის ჩამონათვა",
        alwaysRoundUp: "ყოველთვის ზრდის",
        alwaysRoundBot: "ყოველთვის ჩაკეცის",
        default: "ნაგულისხმევი",
        deleteOldDataTable: "ძველი ცხრილის წაშლა?",
        deleteOldData: "ძველი მონაცემების წაშლა?",
        integer: "მთელი",
        double: "რიცხვული",
        pleaseFillFields: "გთხოვთ, შეავსოთ საჭირო ველები.",
        toastColumnNameNumber: "სვეტის სახელი არ უნდა იწყებოდეს ციფრით.",
        areYouSure: "ნათესავებთან ხარ?",
        columnDeleted: "სვეტი წაიშალა",
        allColumnDeleted: "ყველა სვეტი წაიშალა",
        idCantStartNumber: "ID არ შეიძლება იწყებოდეს ციფრით.",
        uploadFile: "ფაილის ატვირთვა",
        fileViewer: "ფაილის ხილვა",
        ok: "კარგი",
        map: "რუკა",
       
        editPanel: "პანელის რედაქტირება",
        filterColumnName: "ფილტრის სვეტის სახელი",
        hintExamples: "ჯამი, მაგას",
        columnLanguageSettings: "სვეტის ენის პარამეტრები",
      
        fileColumn: "ფაილის სვეტი",
        fileNameColumn: "ფაილის სახელის სვეტი",
        extensionColumn: "გაფართოების სვეტი",
        multipleSelection: "რამდენჯერაც არჩეული",
        addSelectionsRows: "არჩეულების რიგების დამატება",
        mapSettings: "რუკის პარამეტრები",
        formatAndFeatures: "ფორმატი და ფუნქციები",
        enterDateRange: "შეიყვანეთ თარიღის შუალედი",
        filesCanUploadedUsers: "მომხმარებლებმა შეიძლება ატვირთოს ფაილები",
        barcodeColumn: "ბარკოდის სვეტი",
        autoAscend: "ავტო ზრდადობა",
        roundType: "გარკვევა ტიპი",
        font: "შრიფტი",
        columnProcess: "სვეტის გამოძახება",
        exportColumnTotal: "სვეტის ჯამის ექსპორტი",
        outsideElementDisabled: "გამორეკესტრირებული ელემენტების გამორეკესტრირება",
        chat: "ჩატი",
        languages: "ენები",
        display: "გამოსახულება",
        enterYourComment: "შეიყვანეთ კომენტარი",
        comment: "კომენტარები",
        fileUploaded: "ფაილები ატვირთულია",
        pdfViewIsButton: "PDF ხილვა ღილაკად გამოჩენა",
        takeScreenshot: "ეკრანის სურათის შენება",
        alertText: "გაფრთხილების ტექსტი",
        clearButtonVisible: "გასუფთავების ღილაკის ჩვენება",
        allText: "ყველა",
        letter: "მხოლოდ ასო",
        letterandnumber: "ასო და ციფრი",
        letterandsymbol: "ასო და სიმბოლო",
        numberandsymbol: "ციფრი და სიმბოლო",
        showIDColumn: "ID სვეტის ჩვენება",
        showCopyButton: "კოპირების ღილაკის ჩვენება",
        showDeleteButton: "წაშლის ღილაკის ჩვენება",
        showCheckboxButton: "ჩეკბოქსის ღილაკის ჩვენება",
        autoRowHeight: "ავტო სტრიქონის სიმაღლე",
        exportImport: "ფორმის იმპორტი / ექსპორტი",
        export: "ექსპორტი",
        import: "იმპორტი",
        toastImport: "ფორმის იმპორტი დასრულდა.",
        warningApproveImport: "შეასრულეთ ოპერაცია, საჭირო შესწორებების შემდეგ. ფორმის იმპორტი დახურვისას ახალი გახსნა დაიხურება.",
        defaultTodaysDateWithTime: "საშუალოდ დღესა და დროს გააქტიურება",
        filterVisible: "სვეტის ფილტრის ჩვენება",
        updatetableMaxQuantity: "მაქსიმალური განახლებადი რაოდენობა",
        hideQuantity: "რაოდენობის არჩევის დამალება",
        disableQuantityTextbox: "რაოდენობის არჩევის მიერ ჩეკბოქსის შეზღუდვა",
        multiplesOfQuantity: "რაოდენობის ამოცილება/შემცილება რაოდენობის გამრჯელებების საშველებით",
        justnumber: "მხოლოდ ციფრები",
        paymentHourWarning: "გადახდის გასაწყვეტი საათებში გადახდა შეუძლებელია.",
  
        addColumn: "სვეტის დამატება",
        mailSettings: "ელფოსტის პარამეტრები",
        newTaskMailDetail: "წერილის ტექსტი გამოსატანი ახალი ამოხსნისას",
        updateTaskMailDetail: "წერილის ტექსტი გამოსატანი როდესაც ამოხსნა განახლდება",
        taskOnDropMail: "წერილის ტექსტი გამოსატანი როდესაც ამოხსნა გადატანილია",
        selectSMTP: "აირჩიეთ SMTP მისამართი",
        taskCreator: "ამოქმის კავშირი",
        taskEndDate: "ამოქმის ვადაგასწორების თარიღი",
        tags: "ტეგები",
        taskStartDate: "ამოქმის დაწყების თარიღი",
        taskAssigned: "ამოქმის დანიშვნის პირი",
        taskDetail: "ამოქმის დეტალები",
        taskSubject: "ამოქმის სათაური",
        taskPri: "ამოქმის პრიორიტეტი",
        taskEdit: "ამოქმის რედაქტირება",
        addTask: "ახალი ამოქმა",
        taskStatus: "ამოქმის სტატუსი",
       
        selectPriority: "აირჩიეთ პრიორიტეტი",
        excelExportSettings: "Excel ექსპორტის პარამეტრები",
        excelExportRequiredColor: "აუცილებების ველის სვეტის ფერი",
        allDataExcelExport: "მონაცემების ექსპორტი Excel-ში",
        warningApproveImportReport: "დაადასტურეთ ოპერაცია საჭირო შესწორებების შემდეგ. რეპორტის იმპორტი დახურულ რეპორტს.",
        reportName: "რეპორტის სახელწოდება",
        old: "ძველი",
        upperNode: "ზედა წინაშეა",
        pdfViewStaticPdf: "მიმართულება ფიქსირებულ ფაილს",
        actions: "მოქმედებები",
        loading: "ჩატვირთვა",
        exportDataToExcel: "ყველა მონაცემის ექსპორტი Excel-ში",
        showRefreshIcon: "განახლების ღილაკის ჩვენება",
        headerFilter: "სათაურის ფილტრი",
        filterRowVisible: "ძებნის ველი არის სვეტში",
        password: "პაროლი",
        categories: "კატეგორიები",
        pressEnter: "დააჭირეთ Enter-ს",
        historyColumnValue: "მონაცემთა ისტორიის სვეტი",
        hideMobile: "მობილურში დამალება",
        align: "სწორება",
       
        theme: "თემა",
        info: "ინფო",
        showTitle: "სათაურის ჩვენება",
        purple: "მურიანი",
        brown: "ყავახი",
        yellow: "ყვითელი",
        turnOnComments: "კომენტარების ჩართვა",
        showContent: "შიგთავსის ჩვენება",
        canBeHidden: "შეიძლება დამალდეს",
        allowEdit: "რედაქტირება შესაძლებელია",
        allowDelete: "წაშლა შესაძლებელია",
        allowAdd: "დამატების ნება",
        toastDeleteErrorMessage: "ეს კომპონენტი იყენება ფორმის პრეფერენციების სკრიპტებში. მისი წაშლა შეუძლებელია.",
        formOpeningMultiElementEvent: "ფორმის გახსნის მრავალ ელემენტის ღონისძიება",
        resetDatagridLocalSettings: "მონაცემების ადაპტირება ლოკალურ პარამეტრებში",
        loginP1: "River-ი უზრუნველყოფს დაენიჭება კორპორატიული მეხსიერება, რომელშია ინფორმაცია, როგორიცაა წინა ექსპერიენცია, წარუდგენება, ჩაშლა, მოგება, შესაბამისობა და ხალხის ცუდი ან კარგი გამოწერები, თქვენი ორგანიზაციის ან ინსტიტუციის კულტურა და ღიადი მნიშვნელობები, რომლებიც შეიძლება გამოიყენოთ სამომავლო გასაჭირი წმინდაა მოვლენებებში, დამალებებში, გარდაქმნილ და მოქმედებებში, პროდუქტის და სერვისის წარმოებაში და სხვა ინფორმაციაში. ეს ინფორმაცია მიიღება კომპანიის მომსახურების სტრატეგიის და მომსახურების მიზანების საფუძვლებში.",
        loginP2: "River გასარჩევი კორპორატიულ პროცესებს გაუმართავს უფრო ეფექტურად და სწრაფი ვებ და მართვადგური მობილური აპლიკაციების მხარდაჭერით. ყველა რესურსი, რომლის წვდომა გაქვთ, იხილება თქვენს კომპიუტერში, ტაბლეტში ან მობილურ ტელეფონში.",
        loginConnect: "შეერთება",
        loginText: "შესვლა",
        loginRightMore: "მეტი",
        loginHeaderP1: "თქვენი კორპორატიული მეხსიერება",
        loginHeaderP2: "მობილობილობა",
        loginHeaderP3: "გრაფიკული ძალა",
        kanbanaddTasks: "კანბან ამოქმების დამატება",
        tasksstatusDesciption: "გამოიტანეთ კანბან ამოქმების დანიშვნის სვეტის სახელწოდება.",
        taskssubjectDescription: "დაარეგისტრირეთ კანბან ამოქმის სათაური. კანბან სათაური არის სავალდებულო ველი.",
        taskassignedToTask: "დანიშვნილი ახალ ამოქმაში დამინიშვნებული ჯგუფები",
        taskassignedTaskDescription: "ახალ კანბან ამოქმაში დანიშვნის ჯგუფების შერჩევა.",
        addedForTasks: "დამატებული ამოქმებში",
        outlookIntegration: "Outlook ინტეგრაცია",
        loginoutlook: "Outlook კალენდრში შესვლა",
        fetchoutlook: "Outlook კალენდრიდან მონაცემთა მიღება",
       
        progressStatus: "პროგრესის სტატუსი",
        taskColors: "ამოქმის ფერები",
        addcalendarTask: "ამოქმების დამატება კალენდარში",
        addedforTasks: "დამატებული ამოქმებში",
        updatetaskCalendar: "კალენდარის ამოქმის განახლება",
        statusComplete: "ფილტრი სტატუსის დასრულებისას",
        priorityFilter: "პრიორიტეტის ფილტრაცია",
        columnFilter: "სვეტის ფილტრაცია",
        assignPerson: "დანიშვნილი პირები",
        assignGroup: "დანიშვნილი ჯგუფები",
        noTask: "ამოქმები ვერ მოიძებნა",
        resetSort: "სორტირების გადატვირთვა",
        sortASC: "სორტი A - Z",
        sortDESC: "სორტი Z - A",
        sortAppointment: "სორტი ანგარიშის დანიშვნის თარიღის მიხედვით",
        trash: "სანაგვი",
        showOlderMail: "ძველი წერილების ჩვენება",
        mailNotFound: "წერილი ვერ მოიძებნა",
        sendNewMail: "ახალი წერილის გაგზავნა",
        showUnreadMails: "წაუკითხავი წერილების ჩვენება",
        updateColumnMail: "შეტყობინების გაგზავნა კონტაქტს როდესაც ამოქმა დაემატა სვეტში",
        addTaskToOutlook: "ამოქმის დამატება Outlook-ში",
        enterYourMessage: "შეიყვანეთ თქვენი შეტყობინება",
        reminder: "მაღაზია",
        determinedToTask: "ამოქმისთვის დადგენილი დრო",
        remainingTime: "დარჩენილი დრო",
        hours: "საათი",
        withoutPriority: "პრიორიტეტი გარეშე",
        lowPri: "დაბალი პრიორიტეტი",
        normalPri: "საშუალო პრიორიტეტი",
        highPri: "მაღალი პრიორიტეტი",
        showAllTasks: "ყველა ამოქმას ჩვენება",
        showIncompleteTasks: "დაუსრულებელი ამოქმების ჩვენება",
        showCompeletedTasks: "დასრულებული ამოქმების ჩვენება",
        reportDesigner: "ანგარიშის დიზაინერი",
        fileManagementSystem: "ფაილების მართვის სისტემა",
        formDesigner: "ფორმის დიზაინერი",
        boardDesigner: "ბორდის დიზაინერი",
        themeSettings: "თემის პარამეტრები",
        social: "სოციალური",
        profile: "პროფილი",
        contacts: "კონტაქტები",
        files: "ფაილები",
        pages: "გვერდები",
        noResultsFound: "შედეგები ვერ მოიძებნა",
        processes: "პროცესები",
        filterByStatus: "ფილტრი სტატუსის მიხედვით",
        filterByPri: "ფილტრი პრიორიტეტის მიხედვით",
        filterByCol: "ფილტრი სვეტის მიხედვით",
        todoAddTasks: "To-Do ამოქმების დამატება",
        addLabel: "ჩაწერეთ ეტიკეტი",
        showWithSubTasks: "ქვემივალის ამოქმების ჩვენება",
        riverBoard: "River ბარდები",
        notify: "შეტყობინებები",
        likedYourPost: "დასწორებული თქვენი პოსტი",
        likedYourComment: "დასწორებული თქვენი კომენტარი",
        systemNotify: "სისტემის შეტყობინებები",
        readAllNotify: "ყველა შეტყობინების წაკითხვა",
        recommendedUsers: "რეკომენდირებული მომხმარებლები",
        suggestedPosts: "რეკომენდირებული პოსტები",
        sPostReposted: "პოსტი ხელახალა გამოიყენება",
        shareSomething: "რაიმე გაუზარდეთ",
        updatePost: "პოსტის განახლება",
        ifYouDoNotSelectGroup: "თუ არ აირჩევთ ჯგუფს, ყველას შეეძლება ნახვად არსებობდეს თქვენი პოსტი",
        addComment: "კომენტარის დამატება",
        sendComment: "კომენტარის გაგზავნა",
        areYouSureYouWantToDeleteThisPost: "გსურთ პოსტის წაშლა?",
        yes: "კი",
        no: "არა",
        deletePost: "პოსტის წაშლა",
        postSuccesfullyDeleted: "პოსტი წაშლილია",
        deleteComment: "კომენტარის წაშლა",
        areYouSureYouWantToDeleteThisComment: "გსურთ ეს კომენტარის წაშლა?",
        commentSuccesfullyDeleted: "კომენტარი წაშლილია",
        sharePost: "პოსტის გაზიარება",
        areYouSureYouWantToShareThisPost: "გსურთ ამ პოსტის გაზიარება?",
        repostedSuccessfully: "გაზიარდა წარმატებით",
        upload: "ატვირთვა",
        refresh: "განახლება",
        allowedExtentions: "დაშვებული გაფართოებები: JPG, GIF ან PNG. ფაილის მაქსიმალური ზომა",
        systemLandingPagePreference: "მიმართვის გვერდის პრეფერენცია",
        allownonadminuserstosearchforotherusers: "დაშვება არა-ადმინ მომხმარებლებს სხვა მომხმარებელთა ძებნა",
        passwordChange: "პაროლის შეცვლა",
        newPassword: "ახალი პაროლი",
        retypethenewpassword: "შეიტანეთ ახალი პაროლი ხელახლა",
        notifications: "შეტყობინებები",
        showWhoLikesYourPost: "შოუ ვის მოსწორდა თქვენი პოსტი",
        showWhoLikesYourComment: "შოუ ვის მოსწორდა თქვენი კომენტარი",
        aboutMe: "ჩემ შესახებ",
        bio: "ბიოგრაფია",
        dateOfBirth: "დაბადების თარიღი",
        website: "ვებსაიტი",
        phone: "ტელეფონი",
        saveChanges: "შეინახეთ ცვლილებები",
        username: "სახელი",
        content: "შიგაარსი",
        projectManagement: "პროექტის მართვა",
        addPage: "გვერდის დამატება",
        addFolder: "საქაღალდეს დამატება",
        showUsers: "მომხმარებელებს, ვისაც შეუძლებება იხილოს",
        showGroups: "ჯგუფებს, ვისაც შეუძლებება იხილოს",
        readonlyUsers: "მომხმარებელებს, ვისაც წაიკითხოს",
        readonlyGroups: "ჯგუფებს, ვისაც წაიკითხოს",
        columnHeight: "სვეტის სიმაღლე",
        showKanbanCount: "კანბანის რიგის რაოდენობის ინფორმაციის ჩვენება",
        kanbanSettings: "კანბანის პარამეტრები",
        gantSettings: "განტის პარამეტრები",
        schedulerSettings: "კალენდარის პარამეტრები",
        showRowLines: "ხაზების ხაზების ჩვენება",
        activateOutlookConnection: "აქტივირებეთ Outlook შეეთავათება",
        selectOutlookColumn: "აირჩიეთ Outlook სვეტი",
        selectSMTPopoverText: "მეილის SMTP, რომლის გამოყენება შეიძლება კანბანი, განტი, კალენდარი ან მიუთითებელი კომპონენტებში შეიძლება. ",
        menuFilePathId: "River მენიუს ფოლდერის id",
        recycleBinPathName: "River სანაგვეში ფოლდერის სახელწოდება",
        recycleBinPathId: "River სანაგვეში ფოლდერის id",
        fileNameChangedMessage: "ფაილის სახელი შეიცვალა წარმატებით.",
        fileNameChangedErrorMessage: "ფაილის სახელის შეცვლა ვერ ხერხდება.",
        fileCreatedMessage: "ფაილი წარმოებულია წარმატებით.",
        newTaskMailDetailPopoverText: "როდესაც კანბანის, განტის, კალენდარის ან მიუთითებელ კომპონენტში ახდენს ახალი დავა, მეილის კონტენტის ნაწილი, რომელიც შეიძლება გაუგზავნდეს მიმართულებებმა.",
        taskOnDropMailPopoverText: "როდესაც ცხრილი შეიცვალება, ამ ნაწილის ნაწილი გაუგზავნება ავტომატურად მინიშნებული ფოსტას, რომელიც გაიგზავნება კანბანი, განტი, კალენდარი ან მიუთითებელი კომპონენტებში არსებულ დავამატებელ ადამიანებს.",
        createdBy: "შექმნა",
        updatedBy: "განახლებული",
        createdDate: "შექმნის თარიღი",
        updatedDate: "განახლების თარიღი",
        folderPermissionSettings: "ფოლდერის უფლებების პარამეტრები",
        quantity: "ცალი",
        fileManagementPermissionSettings: "ფაილის მართვის უფლებების პარამეტრები",
        fileNotFoundErrorText: "ვერ მოიძებნა ფაილები თქვენს ძიებნის კრიტერიუმებთან სასურველი რეზულტატის.",
        updateTaskMailDetailPopoverText: "როდესაც კანბანში, განტიში, კალენდარში ან მიუთითებელ კომპონენტებში თასი იღება შექმნილ მინიშნებებში განახლების შემდეგ დამატებულ მომხმარებლებს გაუგზავნება.",
        selectOutlookColumnPopoverText: "აირჩიეთ სვეტი, რომელიც შეიცავს ამავარ საქმიან ამბებს.",
        createFolder: "ფოლდერის შექმნა",
        versionHistory: "ვერსიის ისტორია",
        deletePermanently: "სრულად წაშლა",
        recover: "აღდგენა",
        groupPermissions: "ჯგუფის უფლებები",
        allowUpload: "ფაილის ატვირთვის დაშვება",
        allowView: "ფაილის ნახვის დაშვება",
        allowDownload: "ფაილის ჩამოტვირთვის დაშვება",
        permissionType: "უფლების ტიპი",
        applyToSubFolders: "გამოიყენოს ყველა ქვეფოლდერისთვის",
        newFolderName: "ახალი ფოლდერის სახელწოდება",
        tagTips: "ტეგებს უნდა წყვეტილებისაგან წარმოიდგენოს # ნიშანი. ის შეიძლება შეიცავდეს მხარესა და ციფრებს",
        addAsNewVersion: "ჩამოტვირთვა როგორც ახალი ვერსია",
        addAndChangeName: "ატვირთვა როგორც ახალი ვერსია და სახელის შეცვლა",
        skip: "გამოტოვება",
        fileExistError: "იგივე სახელიანი ფაილი არსებობს საქმიან დირექტორიაში",
        processFileShow: "ჩვენება River პროცესი ფოლდერი",
        processFilePathName: "River პროცესი ფოლდერის სახელწოდება",
        processFilePathId: "River პროცესი ფოლდერის იდენტიფიკატორი",
        menuFileShow: "ჩვენება River მენიუ ფოლდერი",
        menuFilePathName: "River მენიუ ფოლდერის სახელწოდება",
        fileCreatedErrorMessage: "ფაილის შექმნა ჩაიშალა.",
        fileDeletedessage: "ფაილის წაშლა დასრულდა.",
        fileDeletedErrorMessage: "ფაილის წაშლა ჩაიშალა.",
        fileMovedMessage: "ფაილი გადატანილია წარმატებით.",
        fileMovedErrorMessage: "ფაილის გადატანა ჩაიშალა.",
        fileCopyMessage: "ფაილი კოპირებულია წარმატებით.",
        fileCopyErrorMessage: "ფაილის კოპირება ჩაიშალა.",
        fileDownloadMessage: "ფაილის ჩამოტვირთვა წარმატებით.",
        fileDownloadErrorMessage: "ფაილის ჩამოტვირთვა ჩაიშალა.",
        fileSizeShowErrorMessage: "ფაილის ზომა დიდია, ასე რომ ვერ იხილავთ. გთხოვთ, ჩამოტვირთოთ ფაილი",
        unsupportedFile: "მხარდაჭერება ფაილზე არ არის",
        fileNameError: "ამ სახელით ფაილი უკვე არსებობს, გთხოვთ, სცადოთ სხვა სახელით",
        fileGivePermissionMessage: "ფაილის დაშვება დასრულდა წარმატებით.",
        fileGivePermissionErrorMessage: "ფაილის დაშვება ვერ ხერხდება. რეგისტრაცია ჩაიშალა.",
        groupName: "ჯგუფის სახელწოდება",
        createGroup: "ჯგუფის შექმნა",
        conversation: "კონვერსაცია",
        startConversation: "კონვერსაციის დაწყება",
        userNotFound: "კონტაქტი ვერ მოიძებნა",
        chatNotFound: "ჩეთი ვერ მოიძებნა",
        themeTypeLight: "ღია",
        themeTypeBorderedLight: "ღია ჩარჩო",
        themeHalfDark: "ნახევარღამე",
        themeDark: "ბნელი",
        themeRouteAnimation: "გადასვლის ანიმაცია",
        themeRouteAnimationFadeInLeft: "მარცხენა ფადი",
        themeRouteAnimationZoomIn: "ზუმი",
        themeRouteAnimationFadeIn: "გამოჩენა",
        themeRouteAnimationNone: "არაფერი",
        themeMenuType: "მენიუს ტიპი",
        themeMenuTypeHorizontal: "ჰორიზონტალური",
        themeMenuTypeVertical: "ვერტიკალური",
        themeMenuCollapse: "შეკუთვნილი მენიუ",
        themeNavbarColor: "ნავიგაციის ზოლის ფერი",
        themeNavbarType: "ნავიგაციის ტიპი",
        themeSticky: "მჭერი",
        themeStatic: "სტატიკური",
        themeFloating: "ფლოატირება",
        themeHidden: "დამალული",
        themeFooterType: "ფუტერის ტიპი",
        catalog: "კატალოგი",
        riverBoards: "რივერ ბორდები",
       
        clipboardDesigner: "ბუფერის დიზაინერი",
        designMode: "თქვენ ხართ დიზაინ რეჟიმში",
        editPage: "გვერდის რედაქტირება",
        rename: "გადარქმევა",
        exportToPdf: "ექსპორტი PDF ფორმატში",
        exportToExcel: "ექსპორტი Excel ფორმატში",
        revenueReport: "შემოსავლეთი ანგარიში",
        header: "სათაური",
        connection: "კავშირი",
        primaryKey: "პირველადი გასაღები",
        secondaryKey: "მეორადი გასაღები",
        completedKey: "შესრულებული გასაღები",
        countKey: "რიცხვის გასაღები",
        statusKey: "სტატუსის გასაღები",
        progressConnection: "პროგრესის კავშირი",
        progressScript: "პროგრესის სკრიპტი",
        progressPrimaryKey: "პროგრესის პირველადი გასაღები",
        
        progressCountKey: "პროგრესის რაოდენობის გასაღები",
        progressPercentageKey: "პროცენტული პროგრესის გასაღები",
        progressColorKey: "პროგრესის ფერის გასაღები",
        keyColumn: "მნიშვნელობის სვეტი",
        currency: "ვალუტა",
        whitePage: "თეთრი გვერდი",
        noReportSelected: "არცერთი ანგარიში არ არის არჩეული",
        noTypeSelected: "არ არის არჩეული ანგარიშის ტიპი",
        whitePageReports: "თეთრ გვერდის ანგარიშები",
        addBranch: "შეავსეთ განაწილების/ქვე-განაწილების დასამატებლად",
        branchName: "განაწილების სახელი",
        rowNumber: "რიგის ნომერი",
        reportTypeSelect: "აირჩიეთ ანგარიშის ტიპი",
        reportDescription: "ანგარიშის აღწერა...",
        useWeb: "გამოიყენეთ ვებზე",
        useMobile: "გამოიყენეთ მობილურზე",
        mainScript: "მთავარი სკრიპტი",
        prerunScript: "გაშვება წინაშე?",
        preScript: "წინაშესკრიპტი",
        testIt: "ტესტირე",
        selectAll: "ყველას არჩევა",
        addReport: "ანგარიშის დამატება",
        selectReportType: "აირჩიეთ ანგარიშის ტიპი",
        reportType: "ანგარიშის ტიპი",
        reportDesingPermission: "მომხმარებლს არ აქვს ანგარიშის დიზაინის უფლებები.",
        reportImportedOpenLeftMenu: "ანგარიში იმპორტირება. შეგიძლიათ გახსნა მარცხნივ მენიუში.",
        forReportExport: "ანგარიშის ექსპორტისთვის, საწყისიდან უნდა აირჩიოთ ანგარიში.",
        anErrorOccurred: "მოხდა შეცდომა",
        reportDeleted: "ანგარიში წაშლილია",
        branchDeleted: "განაწილება წაშლილია",
        addBranchName: "გთხოვთ, შეიყვანოთ განაწილების სახელი",
        addedBranch: "განაწილება დაემატა",
        selectConnectionAddress: "გთხოვთ, აირჩიოთ კავშირის მისამართი",
        enterSqlScript: "გთხოვთ, შეიყვანოთ SQL სკრიპტი",
        enterReportName: "გთხოვთ, შეიყვანოთ ანგარიშის სახელი",
        reportAdded: "ანგარიში დაემატა",
        numberOfDataToBeProcessed: "კავშირი წარმოიშვება. დამუშავებად იქნება შემთხვევით:",
        dataNotFoundCheckYourConnection: "მონაცემები ვერ მოიძებნა! გთხოვთ, შეამოწმოთ კავშირი და SQL ბრძანება",
        connectionNotFoundCheckYourConnection: "კავშირი ვერ მოიძებნა! გთხოვთ, შეამოწმოთ კავშირი და SQL ბრძანება",
        fillConnectionAndSql: "გთხოვთ, შეავსოთ კავშირი და SQL ბრძანება",
        allTypesActivate: "ყველა ტიპი გააქტიურება ჩვენების დროს",
        passwordRequired: "საჭიროა პაროლი",
        showLess: "ნაკლებად ჩვენება",
        showMore: "მეტის ჩვენება",
        authority: "ავტორიზაცია",
        preScriptDescription: "წინაშე სკრიპტის აღწერა",
        enterReportFilters: "შეიყვანეთ ანგარიშის ფილტრები",
        gridDesigner: "გრიდის დიზაინერი",
        addMainBranch: "შეავსეთ ძირის განაწილება",
        resetSettings: "პარამეტრების გადატვირთვა",
        columsToGroup: "ჯგუფების მონაცემების ჯიგრული მენიუს გამოყენება",
        customize: "მორგება",
        colorType: "ფერის ტიპი",
        formatManagement: "ფორმატირების მართვა",
        bottomTotalColumn: "ქვერის ჯამის სვეტი",
        formatType: "ფორმატის ტიპი",
        datagridSettingsReset: "მონაცემთა გრიდის პარამეტრები იქნება გადატვირთული",
        unexpectedError: "უკაცრად შეცდომა შემოგვაქვს",
        changesSaved: "ცვლილებები შენახულია",
        
        addGantTask: "დაამატე განტის დავა",
        updGantTask: "განტის დავა განახლება",
        updSchedulerTask: "კალენდარული დავა განახლება",
        updTodoTask: "დაამატე To-Do დავა",
        completeTask: "დაასრულე დავა",
        cantBeEmptyKanban: "დავის სათაური, დეტალები და დავას კავშირგამოყენებულების ველები ვერ იქნება ცარიელი",
        successAdd: "დავა წარმოადგენს წარმატებით დამატებას",
        successUpd: "დავა წარმოადგენს წარმატებით განახლებას",
        statusChangeTodo: "დავა დავას სტატუსის შეცვლის შემდეგ შესრულდა წარმოება",
        reminderSended: "დავაზე გაგზავნილი გახსნილია გასათესავად",
        askForCompleteTask: "ნამდვილად გსურთ დავას დამთავრება?",
        infoForCompletedTask: "ანგარიშის ქვემოსენებებიც შესრულდება.",
        noPermisison: "თქვენ არ გაქვთ უფლებები.",
        successDependencyAdded: "დავას დეპენდენცია წარმოებაში წარმოებად დამატებულია",
        filterimportant: "ფილტრი შემოწმების მიხედვით",
        enterEmail: "შეიყვანეთ ელ. ფოსტის მისამართი",
        reply: "პასუხი",
        draft: "გასაგზავნად",
        newCol: "ახალი სვეტი",
        thickness: "სიმჭაბულე",
        distanceToUpperComponent: "მიწვდომა ზემო კომპონენტთან",
        companyName: "კომპანიის სახელი",
        showEmailModule: "ელ. ფოსტის მოდულის ჩვენება",
        distanceToBottomComponent: "მიწვდომა ქვემო კომპონენტთან",
        canNonAdminUsersPost: "უადასახელო მომხმარებელებმა შეიძლება პოსტი გააკეთონ",
        columnColor: "სვეტის ფერი",
        byWho: "მიერ",
        updated: "განახლდა",
        commentAdded: "კომენტარი დაემატა",
        completed: "დასრულებული",
        addRemoveColumns: "სვეტების დამატება/წაშლა",
        searchMessage: "შეტანილი წერილების ძიება",
        riverReports: "River BI ანგარიშები",
        forms: "ფორმები",
        
        searchinforms: "ფორმების ძიება",
        searchVoiceMessage: "წერილი, რომლის ძიებას ხართ აღმოაჩინეთ, არის ამ ხმის ფაილში",
        searchVoiceMessages: "ხმის წყაროების ძიება",
        showSocialNotifications: "სოციალური შეტყობინებების ჩვენება",
        showProjectManagementNotifications: "პროექტის მართვის შეტყობინებების ჩვენება",
        showProcessNotifications: "პროცესის შეტყობინებების ჩვენება",
        pageDesingPermission: "მომხმარებლს არ აქვს გვერდის დიზაინის უფლებები.",
        filemanagementDesingPermission: "მომხმარებლს არ აქვს ფაილების მართვის უფლებები.",
        taskDelete: "დავა წაიშლება.",
        tasksWillDeleted: "ნამდვილად გსურთ დავას წაშლა?",
        componentSettings: "კომპონენტის პარამეტრები",
        aboutComponent: "კომპონენტის შესახებ",
        sureSaveChanges: "ნამდვილად გსურთ ცვლილებების შენახვა?",
        showDeletedNotifications: "წაშლილი შეტყობინებების ჩვენება",
        shareSometthing: "რაიმე რამ გაუზიარე",
        resetPageLayout: "გვერდის განახლება",
        downloadingSS: "სქრინშოტის ჩამოტვირთვა. გთხოვთ, მოიცადოთ.",
        showHorizontalLines: "ჰორიზონტალური ხაზების ჩვენება",
        turnOnVerticalHide: "ვერტიკალური დამალვას ჩართვა",
        whichHorizontalLinelinesAreNotVisible: "რომელი ჰორიზონტალური ხაზი - ხაზები არ ჩაინება",
        priority: "პრიორიტეტი",
        boardSidebarMessageField: "დავალების შეტყობინების ველი",
        boardSidebarExtraField: "დავალების დამატებითი კომპონენტის ველი",
        boardSidebarTaskHistory: "დავალების ისტორია",
        loadMoreNotifications: "მეტი შეტყობინების ჩვენება",
        deletedNotifications: "წაშლილი შეტყობინებები",
        noFormNotification: "ჯგუფის შეტყობინება ჯერ არ არის",
        noDeletedFormNotifications: "წაშლილი ჯგუფის შეტყობინება ჯერ არ არის",
        noDeletedBoardNotification: "წაშლილი კუთხეების შეტყობინება ჯერ არ არის",
        noBoardNotification: "კუთხეების შეტყობინება ჯერ არ არის",
        noDeletedSocialNotification: "წაშლილი სოციალური მედიაში შეტყობინება ჯერ არ არის",
        noSocialNotification: "სოციალურ მედიაში შეტყობინება ჯერ არ არის",
        deleteAll: "ყველას წაშლა",
        markAllRead: "ყველას წაკითხულად ნიშნა",
        board: "მიმართულება",
        form: "ფორმა",
        formNotifications: "ფორმის შეტყობინებები",
        boardNotifications: "მიმართულების შეტყობინებები",
        socialNotifications: "სოციალური შეტყობინებები",
        email: "ელ. ფოსტა",
        oldFormName: "ძველი ფორმის სახელი",
        newFormName: "ახალი ფორმის სახელი",
        oldFormVariable: "ძველი ფორმის ცვლადი",
        newFormVariable: "ახალი ფორმის ცვლადი",
        oldCategory: "ძველი კატეგორია",
        newCategory: "ახალი კატეგორია",
        connectionInFile: "შეერთება ფაილში",
        yourConnection: "თქვენი შეერთება",
        element: "ელემენტი",
        script: "სკრიპტი",
        formClosingEvent: "ფორმის დახურვის მოვლა",
        message: "შეტყობა",
        formExportTextError: "ფორმაში არაფერი დაემატა ან ფორმა არ შეინახა. გთხოვთ, ამ პრობლემის გასაწმენდად გაასწოროთ.",
        checkRequiedFields: "აუცილებელი ველების შემოწმება",
        dontCheckRequiredFields: "აუცილებელი ველების შემოწმება არ გაიარებს",
        createPdfWarning: "თუ მონიშნულია, ფორმა არ შეინახავს და არ იხსნება",
        cratePdf: "PDF შექმნა",
        identityNumber: "იდენტიფიკაციის ნომერი",
        minPaymentHour: "მინ საათი",
        maxPaymentHour: "მაქს საათი",
        darkBlue: "მუქი ლურჯი",
        orange: "ორანჯე",
        component: "კომპონენტი",
        properties: "თვისებები",
        dmsPath: "DMS გზა",
        dashboard: "დაფა",
        slider: "სლაიდერი",
        list: "სია",
        otherImages: "სხვა სურათები",
        unitScript: "ერთიანი სკრიპტი",
        updatableMaxQuantity: "განახლებადი მაქს. რაოდენობა",
        newPage: "ახალი გვერდი",
        code: "კოდი",
        resetHiddenComponent: "დამალულ სამიზნე კომპონენტის მნიშვნელობის განულება",
        bold: "მსხვილი",
        italic: "იტალიკური",
        stringFormat: "სიმკვიდრეების ფორმატი",
        videoUploaded: "ვიდეო ატვირთულია",
        fillInAllFields: "შეავსეთ ყველა ველი",
        normalPaging: "ჩვეულებრივი გვერდები",
        pageSize: "გვერდის ზომა",
        special: "სპეციალური",
        link: "ბმული",
        fontWeight: "შრიფტის წონა",
        pdfFileNotFound: "PDF ფაილი არ არის არსებობს",
        themeMenuHidden: "მალული მენიუ",
        likedOn: "მოეწონა",
        addedTask: "დამატებული დავალება",
        updatedTask: "დასახელებული დავალება განახლებულია",
        messageAddedToTask: "შეტყობინება დაემატა ამოცანას",
        deleteTask: "დასახელებული დავალება წაშლილია",
        completedTask: "დავალება დასრულებულია",
        subCategory: "ქვეკატეგორია",
        toTheBoard: "გამგეობისკენ",
        hideTaskDetail: "დავმალო დავალების დეტალები",
        selectData: "მონიშნე მაღაზია",
        noContentYet: "ჯერაც კონტენტი არ არის",
        addRowBetween: "სტრიქონის დამატება",
        userPermissions: "მომხმარე უფლებები",
        askForPassword: "პაროლის შეკითხვა გსურთ?",
        passwordIsWrong: "პაროლი არის შეცდომა, გთხოვთ ხელახლა სცადოთ.",
        pleaseEnterPassword: "გთხოვთ, გამოიყენოთ თქვენი პაროლი გასაგრძელებლად.",
        onePaymentEachSave: "თითო შენობისთვის ერთხელ გადახდა",
        riverModules: "RIVER მოდულები",
        processHistory: "პროცესის ისტორია",
        commentHasBeenMadeAtThisStage: "კომენტარი დამატებულია ამ ეტაპზე",
        clearSelection: "შერჩევა გასუფთავება",
        allowDownloadVersionHistoryDocuments: "ვერსიის ისტორიის დოკუმენტების ჩამოტვირთვა დაშვება",

        delegated: "დელეგირებული",
        openDocxToPdf: "ფაილის გახსნა PDF ფორმატში",
        activationStartDate: "აქტივაციის დაწყების თარიღი",
        activationEndDate: "აქტივაციის დამთავრების თარიღი",
        changeActivationDate: "აქტივაციის თარიღის შეცვლა",
        showArchive: "არქივის ჩვენება",
        hideArchive: "არქივის დამალვა",
        tasks: "დავალებები",
        maxFileCountLimit: "ფაილების მაქსიმალური რაოდენობა",
        fileCountLimit: "ფაილების რაოდენობის ლიმიტი",
        numberOfFilesExceeded: "ფაილების რაოდენობა გადაავლეს",
        numberOfFilesLimit: "ფაილების რაოდენობის ლიმიტი"
    }
  
  }
