import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AppInfoService } from '@shared/services/common/app-info.service';
import { NoteModel } from './note.model';

@Injectable({
    providedIn: 'root'
})
export class NoteService {

   
    constructor(public appInfo: AppInfoService, private http: HttpClient) {
       
    }
    saveNote(model: any) {
        debugger
        return this.http.post<any>(this.appInfo.appUrl + "api/note/savenote", JSON.stringify(model), this.appInfo.httpOptions);
    }


    getNoteList(id: number) {
        
        return this.http.get<any>(this.appInfo.appUrl + "api/note/getnotelist?id="+ id, this.appInfo.httpOptions);

    }



    getNote(id: number) {
        let params = new HttpParams().set("id", id);
        return this.http.get(this.appInfo.appUrl + "api/note/getnote?id=" + id, this.appInfo.httpOptions);
    }

    deleteNote(userId: number,id:number) {
        return this.http.get<any>(`${this.appInfo.appUrl}api/note/deletenote?id=${id}&userId=${userId}`, this.appInfo.httpOptions);
    }



}
