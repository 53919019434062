import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AppInfoService } from '@shared/services/common/app-info.service';
import { SchemeModel } from '@shared/models/scheme-model';

@Injectable({
    providedIn: 'root'
})
export class TaskService {

    constructor(public appInfo: AppInfoService, private http: HttpClient) {

    }

    getCategoryList() {
        return this.http.get(this.appInfo.appUrl + "api/task/getcategorylist", this.appInfo.httpOptions);
    }

    getSchemeList(): Observable<SchemeModel[]> {

        let params = new HttpParams().set("userId", this.appInfo.currentUser.id);
        
        return this.http.get<SchemeModel[]>(this.appInfo.appUrl + "api/task/getschemalist", { params: params });
    }

    getJustSchemeList(): Observable<SchemeModel[]> {

        let params = new HttpParams().set("userId", this.appInfo.currentUser.id);
        
        return this.http.get<SchemeModel[]>(this.appInfo.appUrl + "api/task/getjustschemalist", { params: params });
    }

    getSidebarMenu(): Observable<any> {

        let params = new HttpParams().set("userId", this.appInfo.currentUser.id);
        
        return this.http.get<SchemeModel[]>(this.appInfo.appUrl + "api/task/getsidebarmenulist", { params: params });
    }

    getMenuList(): Observable<any> {
        return this.http.get<SchemeModel[]>(this.appInfo.appUrl + "api/task/getmenulist", this.appInfo.httpOptions);
    }
    getSchedulerMenuAndFormDatas(): Observable<any> {
        return this.http.get<SchemeModel[]>(this.appInfo.appUrl + "api/task/getschedulermenuandformdatas", this.appInfo.httpOptions);
    }

    SetFavorite(schemeId): Observable<any> {

        let params = new HttpParams().set("userId", this.appInfo.currentUser.id).set("schemeId", schemeId);
        
        return this.http.get<any>(this.appInfo.appUrl + "api/task/updatefavorite", { params: params });
    }
    addClickedUsersForChat(userId:number,instanceId:number) {
       
        return this.http.post<any>(`${this.appInfo.appUrl}api/task/addclickedusersforchat?userId=${userId}&instanceId=${instanceId}`, this.appInfo.httpOptions);
    }


    GetProcessStarterId(processFormId): Observable<any> {

        let params = new HttpParams().set("processFormId", processFormId);
        
        return this.http.get<any>(this.appInfo.appUrl + "api/task/getprocessstarterid", { params: params });
    }
    setTaskChatData(model: any) {
        
        return this.http.post<any>(this.appInfo.appUrl + "api/task/settaskchatdata", JSON.stringify(model), this.appInfo.httpOptions);
    } 
    updateTaskChatData(id: number, content: string) {
        return this.http.post<any>(`${this.appInfo.appUrl}api/task/updatetaskchatdata?id=${id}&content=${content}`, this.appInfo.httpOptions);
    }
    deleteTaskChatData(id: number) {
        return this.http.post<any>(this.appInfo.appUrl + "api/task/deletetaskchatdata?id=" + id, this.appInfo.httpOptions);

    }
    getTaskChatData(instanceId: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/task/gettaskchatdata?instanceId=" + instanceId, this.appInfo.httpOptions);

    }
 

    GetFavoriteList(): Observable<any> {

        let params = new HttpParams().set("userId", this.appInfo.currentUser.id);
        
        return this.http.get<any>(this.appInfo.appUrl + "api/task/getfavoritelist", { params: params });
    }

    getTaskList(userId, filter) {
        debugger
        let params = new HttpParams().set("uId", userId).set("filter", filter);
        
        return this.http.get(this.appInfo.appUrl + "api/task/gettasks", { params: params });
    }

    getHistoryPage(id) {
        let params = new HttpParams().set("userId", this.appInfo.currentUser.id).set("type", id);
        
        return this.http.get(this.appInfo.appUrl + "api/task/gethistorypage", { params: params });
    }

   


    errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
}
