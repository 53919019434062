export const locale = {

    lang: 'ARAR',

    data: {

        loginButton: "تسجيل الدخول",

        userName: "اسم المستخدم",

        pass: "كلمة المرور",

        remember: "تذكرنى",

        forgotPass: "نسيت كلمة المرور",

        modules: "الوحدات",

        generic: "عام",

        search: "يبحث",

        logout: "تسجيل خروج",
        generateLink: "توليد الارتباط",
        fileLink:"رابط الملف",
        givePasswordToTheLink: "إعطاء كلمة المرور إلى الرابط",
        giveTheLinkAnExpirationDate: "أعط الرابط تاريخ انتهاء الصلاحية",

    waitingJob: "عملي المعلق",

        myStarted: "بدأت",

        myIncluded: "أنا مشمول",

        myCompleted: "الأعمال المنجزة",

        jobList: "قائمة الوظائف",

        formNo: "نموذج لا",

        status: "حالة",

        starter: "بدأت",

        assigned: "مُكَلَّف",

        timeoutDate: "تاريخ المهلة",

        startDate: "تاريخ البدء",

        processing: "تجهيز",

        you: "أنت",

        history: "التاريخ",

        description: "الوصف",

        date: "التاريخ",

        growShrink: "زيادة/تقليص",

        close: "إغلاق",

        gridReset: "إعادة ضبط إعدادات الشبكة",

        columnSelect: "اختيار العمود",

        jobName: "اسم الوظيفة",

        keyword: "كلمة مفتاحية",

        completion: "اكتمال",

        all: "الكل",

        completedDate: "تاريخ الانتهاء",

        completions: "تم الانتهاء",

        ongoing: "جاري",

        formName: "اسم النموذج",

        sunday: "الأحد",

        monday: "الاثنين",

        tuesday: "الثلاثاء",

        wednesday: "الأربعاء",

        thursday: "الخميس",

        friday: "الجمعة",

        saturday: "السبت",

        pdfExport: "تصدير PDF",

        sendPassword: "إرسال كلمة المرور",

        assignedDate: "تاريخ التعيين",

        events: "أحداث",

        new: "جديد",

        save: "حفظ",

        formula: "صيغة",

        selectDataSource: "اختيار مصدر البيانات",

        requiredField: "حقل مطلوب",

        suffix: "لاحقة",

        valueColumn: "عمود القيمة",

        showLength: "عرض عدد المهام في الأعمدة",

        clearButton: "زر مسح",

        allCaps: "كل الحروف كبيرة",

        autoAsc: "تصاعدي تلقائيًا",

        width: "العرض",

        length: "الطول",

        functions: "وظائف",

        selectElement: "اختيار العنصر",

        selectColumn: "اختيار العمود",

        edit: "تحرير",

        changeEventActions: "تغيير إجراءات الحدث",

        barcode: "باركود",

        systemInfo: "معلومات النظام",

        prefix: "بادئة",

        max: "الحد الأقصى",

        showMinMaxInfo: "عرض معلومات الحد الأدنى والأقصى",

        selectUser: "اختيار المستخدم",

        spinButton: "زر الدوران",

        numberIncDecButton: "زر زيادة/تقليل العدد",

        decimal: "عدد عشري",

        numeral: "عدد",

        wrongFormat: "أدخلت التنسيق الخاطئ",

        height: "الارتفاع",

        microphone: "الميكروفون",

        notifyDataReset: "إعادة تعيين البيانات",

        data: "بيانات",

        dynamicData: "بيانات ديناميكية",

        manualData: "بيانات يدوية",

        selectDataGrid: "اختيار جدول البيانات",

        overrideUserChange: "استبدال تغييرات المستخدم",

        textColumn: "عمود النص",

        makeInitialValue: "القيمة الافتراضية الأولية",

        undo: "تراجع",

        copy: "نسخ",

        preview: "معاينة",

        delete: "حذف",

        cancel: "إلغاء",

        languageSettings: "إعدادات اللغة",

        messageSqlLinkDeleted: "بما أنك أضفت البيانات يدويًا، سيتم حذف نص الـ SQL وعنوان الرابط! هل تريد المتابعة؟",

        permissions: "أذونات",

        objectid: "معرّف الكائن",

        fontSize: "حجم الخط",

        fontWidth: "عرض النص",

        fontStyle: "نمط الخط",

        underline: "تسطير",

        textColor: "لون النص",

        visibility: "الرؤية",

        textAlignment: "محاذاة النص",

        settings: "الإعدادات",

        messageUnsaved: "هل أنت متأكد من أنك تريد الخروج دون حفظ التغييرات؟",

        warning: "تحذير!",

        userAuthorizations: "إذن المستخدم",

        formPreferences: "تفضيلات النموذج",

        formOpeningevent: "حدث فتح النموذج",

        chooseConnectionAddress: "اختيار عنوان الاتصال",

        selectPriorty: "اختيار الأولوية",

        loadingEditor: "جاري تحميل المحرر...",

        showAddButton: "عرض زر إضافة العمود",

        update: "تحديث",

        connectionID: "معرّف الاتصال",

        formPreview: "معاينة النموذج",

        nameDisplayedform: "اسم النموذج المعروض",

        formNamevariable: "متغير اسم النموذج",

        category: "الفئة",

        formColor: "لون النموذج",

        generalForm: "نموذج عام",

        saveForm: "حفظ النموذج",

        messageNeworupdate: "سيتم حذف التغييرات التي أجريتها على النموذج الحالي. هل تريد المتابعة؟",

        messageDelete: "هل أنت متأكد من أنك تريد حذف النموذج؟",

        toastDeleted: "تم حذف النموذج",

        toastError: "عذرًا، حدث خطأ أثناء المعالجة",

        alertDifferentID: "تم استخدام هذا المعرّف في مكان آخر، يرجى تحديد معرّف مختلف.",

        confirmChanges: "تأكيد التغييرات",

        toastUserpermission: "المستخدم ليس لديه إذن تصميم النموذج.",

        toastCodeeditor: "المحرر البرمجي موجود بالفعل",

        messageDeletesure: "سيتم حذف العناصر في هذا العنصر",

        messageSure: "هل أنت متأكد؟",

        successful: "ناجح",

        error: "خطأ",

        toastFormupdate: "تم تحديث النموذج",

        toastMistake: "عذرًا، حدث خطأ أثناء المعالجة",

        toastSaved: "تم الحفظ",

        toastCopied: "تم النسخ",

        toastEmptyFormMessage: "لم يتم إضافة عناصر النموذج بعد",

        toastGiveName: "يرجى إعطاء النموذج اسمًا",

        toastGiveVariableName: "يرجى إعطاء اسم لمتغير اسم النموذج",

        toastVariableNameNumberWarning: "لا يمكن أن يبدأ اسم المتغير برقم",

        formCopy: "نسخ النموذج",

        messageButtonDelete: "هل أنت متأكد من أنك تريد حذف الزر؟",

        noDataFound: "لم يتم العثور على بيانات",

        language: "اللغة",

        filterDatagrid: "تصفية جدول البيانات",

        addManualData: "إضافة بيانات يدوية أثناء التشغيل",

        dateFormat: "تنسيق التاريخ",

        messageSqlLinkDelete: "نظرًا لأنك أضفت البيانات يدويًا، سيتم حذف نص الـ SQL وعنوان الرابط! هل تريد المتابعة؟",

        timeOption: "خيار الساعة",

        defaultTodaysDate: "السماح بتاريخ اليوم الافتراضي",

        dateRange: "نطاق التاريخ",

        minToday: "الحد الأدنى: اليوم",

        maxToday: "الحد الأقصى: اليوم",

        dateRangeDay: "نطاق التاريخ (يوم)",

        enterDateRangeValue: "أدخل قيمة نطاق التاريخ",

        imageIdColumn: "عمود المعرّف",

        statusSettings: "إعدادات الحالة",

        active: "نشط",

        passive: "غير نشط",

        enable: "تمكين",

        disable: "تعطيل",

        visible: "مرئي",

        invisible: "غير مرئي",

        items: "العناصر",

        showItems: "عرض العناصر",

        array: "مصفوفة",

        letLabelAppear: "عرض التسمية",

        add: "إضافة",

        horizontal: "أفقي",

        vertical: "عمودي",

        text: "نص",

        alertFillRequiredFields: "يرجى ملء الحقول المطلوبة",

        alertSignatureField: "لا يمكن أن يكون حقل التوقيع فارغًا",

        messageApproveTransaction: "هل توافق على العملية؟",

        button: "زر",

        reasonforRefusal: "سبب الرفض",

        enterReasonRejection: "أدخل سبب الرفض",

        reject: "رفض",

        addDeleteButton: "إضافة/حذف زر",

        showBorder: "ظهور الحدود",

        buttons: "أزرار",

        clean: "مسح",

        buttonSettings: "إعدادات الزر",

        saveLocation: "موقع الحفظ",

        paymentButton: "زر الدفع",

        paymentProvider: "مقدم الدفع",

        price: "السعر",

        numberOfInstallments: "عدد الأقساط",

        descriptionField: "حقل الوصف",

        current: "الحالي",

        currentForm: "النموذج الحالي",

        name: "الاسم",

        surname: "اللقب",

        country: "البلد",

        city: "المدينة",

        address: "العنوان",

        authorizations: "الإذن",

        users: "المستخدمون",

        user: "مستخدم",

        givePermission: "منح إذن",

        groups: "المجموعات",

        group: "مجموعة",

        saveandSend: "حفظ وإرسال",

        approve: "الموافقة",

        sendBack: "إرجاع",

        deslineAndEnd: "رفض وإنهاء",

        complete: "اكتمال",

        blue: "أزرق",

        gray: "رمادي",

        green: "أخضر",

        red: "أحمر",

        left: "اليسار",

        right: "اليمين",

        color: "اللون",

        symbol: "الرمز",

        position: "الموقع",

        action: "الإجراء",

        total: "الإجمالي",

        filePath: "مسار الملف",

        fileName: "اسم الملف",

        fileUpload: "رفع الملف",

        choose: "اختيار",

        updateData: "تحديث البيانات",


        show: "عرض",

        columns: "أعمدة",

        changeEvent: "تغيير الحدث",

        columnSettings: "إعدادات العمود",

        columnName: "اسم العمود",

        deleteColumn: "حذف العمود",

        columnSqlScripts: "نصوص SQL للعمود",

        imageNameColumn: "عمود اسم الملف",

        columnLanguageSetting: "إعداد لغة العمود",

        dataSource: "مصدر البيانات",

        filter: "تصفية",

        reset: "إعادة تعيين",

        column: "عمود",

        image: "صورة",

        searchLocation: "البحث عن الموقع...",

        draggable: "قابل للسحب",

        usersCanChange: "يمكن للمستخدمين التغيير",

        multiMarker: "مؤشر متعدد",

        markerLimit: "حد الإشارات (0: بدون حدود)",

        meetingType: "نوع الاجتماع",

        meetingName: "اسم الاجتماع",


        loadingFiles: "جاري تحميل الملفات...",

        fileSizeExceeded: "تم تجاوز حجم الملف",

        filesLoaded: "تم تحميل الملفات",

        condition: "الشرط",

        value: "القيمة",

        connectionAddress: "عنوان الاتصال",

        imageTypeColumn: "عمود نوع الملف",

        visibleColumn: "عمود مرئي",

        selectionMode: "وضع التحديد",

        selectMode: "وضع الاختيار",

        datagridFiltering: "تصفية جدول البيانات",

        imageDataColumn: "عمود الملف",


        selectDataColumn: "اختيار عمود جدول البيانات",

        dataFormat: "تنسيق البيانات",


        photoPreview: "معاينة الصورة",

        videoPreview: "معاينة الفيديو",

        displayPhoto: "عرض الصورة",

        displayVideo: "عرض الفيديو",

        imageColumn: "عمود الصورة",

        videoColumn: "عمود الفيديو",


        columnNameAppear: "اسم العمود المراد الظهور له",


        selected: "المحدد",

        inStock: "متوفر في المخزن",

        messageSave: "هل أنت متأكد من أنك تريد حفظ التغييرات؟",

        gallery: "معرض الصور",

        dollar: "دولار",

        sterling: "إسترليني",

        underPhoto: "تحت الصورة",

        topPhoto: "أعلى الصورة",


        byCategory: "بالفئة",

        dashboardShowHide: "عرض/إخفاء لوحة القيادة",

        showDetail: "عرض التفاصيل",

        hideDetail: "إخفاء التفاصيل",

        decrease: "تقليل",

        increase: "زيادة",

        watch: "شاهد",

        watchMovie: "مشاهدة فيلم",

        noRecordsFound: "لم يتم العثور على سجلات!",

        appearance: "المظهر",

        numberofColumns: "عدد الأعمدة",

        pagingRecords: "سجلات الأجزاء",

        detailLocation: "موقع التفصيل",

        hideImages: "إخفاء الصور",

        quantityCategoryDisable: "تعطيل الفئة عند زيادة الكمية",

        general: "عام",

        idColumn: "عمود المعرّف",

        availableQuantity: "الكمية المتاحة",

        categoryColumn: "عمود الفئة",

        photoColumn: "عمود الصورة",

        titleColumn: "عمود العنوان",

        priceColumn: "عمود السعر",

        updatablePrice: "سعر قابل للتحديث",

        videoGallery: "معرض الفيديو",

        uniqueComponent: "عنصر فريد",

        showVariant: "عرض الاختلاف",

        transferTotalAmount: "نقل المبلغ الإجمالي",


        title: "العنوان",

        hide: "إخفاء",

        detail: "التفاصيل",

        priceQuery: "استعلام السعر",

        quantityQuery: "استعلام الكمية",


        uniqueComponentError: "خطأ في العنصر الفريد",

        joinColumn: "عمود الانضمام",

        nameColumn: "عمود الاسم",

        variantScript: "نص الاختلاف",

        variantImages: "صور الاختلاف",

        variantJoinColumn: "عمود الانضمام للاختلاف",

        showAll: "عرض الكل",

        showSelected: "عرض المحدد",

        showByCategory: "عرض حسب الفئة",

        showInStock: "عرض ما هو متوفر في المخزن",

        notYetAdded: "لم يتم الإضافة بعد",

        added: "تمت الإضافة",

        startingDate: "تاريخ البدء",

        endDate: "تاريخ الانتهاء",

        themeFeatures: "ميزات السمة",

        menu: "القائمة",

        selectMenu: "اختيار القائمة",

        selectSubmenu: "اختيار القائمة الفرعية",

        selectMenuBrowser: "اختيار القائمة من المتصفح",

        nameElement: "عنصر الاسم",

        subject: "الموضوع",

        picture: "الصورة",

        pictureElement: "عنصر الصورة",

        startingDateElement: "عنصر تاريخ البدء",

        endDateElement: "عنصر تاريخ الانتهاء",

        usersCanEdit: "المستخدمون يمكنهم التحرير",

        thisPropertyCant: "لا يمكن استخدام هذا الخاصية إذا كانت البيانات تُسترجع من قاعدة البيانات SQL",

        calendarDisplay: "خصائص عرض التقويم",

        monthly: "شهريًا",

        generalDisplayLunarDays: "عرض عام لأيام القمر",

        agenda: "جدول الأعمال",

        listofWeeklyEvents: "قائمة الأحداث الأسبوعية",

        weekly: "أسبوعيًا",

        weekend: "عطلة نهاية الأسبوع",


        generalDisplayWeekDays: "عرض عام لأيام الأسبوع",

        showWeekendDaysWhileWeekdays: "عرض أيام العطلة أثناء عرض أيام الأسبوع",

        daily: "يوميًا",

        listofDailyEvents: "قائمة الأحداث اليومية",

        startTime: "وقت البدء",

        endTime: "وقت الانتهاء",

        changeDefaultColor: "تغيير الألوان الافتراضية",

        weekdayColor: "لون أيام الأسبوع",

        weekendColor: "لون أيام العطلة",

        eventColor: "لون الحدث",

        groupBySql: "تجميع حسب SQL",

        groupColumnName: "اسم عمود التجميع",

        assignedColumnName: "اسم عمود التعيين",

        taskPriortiyColumnName: "اسم عمود أولوية المهمة",

        taskSubjectColumnName: "اسم عمود عنوان المهمة",

        taskDetailColumnName: "اسم عمود تفاصيل المهمة",

        taskQuery: "استعلام المهمة",

        taskKeyExpression: "تعبير مفتاح المهمة",

        taskTitleExpression: "تعبير عنوان المهمة",

        taskStartDateExpression: "تعبير تاريخ بدء المهمة",

        taskEndDateName: "اسم تاريخ انتهاء المهمة",

        taskParentExpression: "تعبير المهمة الأصلية",

        taskProgressExpression: "تعبير تقدم المهمة",

        taskColorExpression: "تعبير لون المهمة",

        taskConnections: "اتصالات المهمة",

        diagramClear: "هل أنت متأكد أنك تريد مسح الرسم البياني؟ لا يمكن التراجع عن هذا الإجراء.",

        uniqueComponentMenuCloneWarning: "يرجى تغيير العنصر الفريد قبل النسخ.",

        filesLoading: "جارٍ تحميل الملفات...",

        couldnotbeLoaded: "تعذر التحميل.",

        fileSizeLimit: "حد حجم الملف",

        filesUploaded: "تم تحميل الملفات",

        filesWillDeleted: "سيتم حذف الملفات!",

        filesSureDelete: "هل أنت متأكد أنك تريد حذف الملفات؟",

        fileDelete: "هل تريد حذف الملف؟",

        uploadedPhotos: "",

        maxSize: "الحجم الأقصى",

        center: "وسط",

        size: "الحجم",

        canItBeUpdated: "يمكن تحديثه بعد التثبيت؟",

        uploadedFiles: "الملفات المحملة",

        type: "النوع",

        showdeleteOldData: "خيارات حذف البيانات القديمة",

        videoBrowser: "متصفحك لا يدعم عنصر الفيديو.",

        deleted: "تم الحذف",

        theseComponentElementsDeleted: "سيتم حذف عناصر هذا المكون.",

        invalidTarget: "هدف غير صحيح",

        ridge: "خط مستقيم",

        dotted: "نقطي",

        dashed: "متقطع",

        addRowColumn: "إضافة صف / عمود",

        dataPagingShow: "الحصول على البيانات كصفحات",

        borderType: "نوع الحدود",

        maxCharacters: "أقصى عدد من الأحرف",

        edgeThickness: "سمك الحافة",

        lineSpacing: "تباعد الخطوط",

        settingsRow: "إعدادات الصف / العمود",

        addRow: "إضافة صف",

        deleteRow: "حذف صف",

        rowPermission: "أذونات الصف",

        addTabs: "إضافة علامات تبويب",

        deletePanel: "حذف اللوحة",

        tabPermission: "أذونات علامة التبويب",

        addPanel: "إضافة لوحة",

        panelPermission: "أذونات اللوحة",

        backgroundColor: "لون الخلفية",

        saveAndCloseChildForm: "حفظ وإغلاق هذا النموذج الفرعي أثناء فتح نموذج جديد",

        icon: "رمز",

        selectForm: "اختيار النموذج",

        fontColor: "لون الخط",

        minCharacters: "الحد الأدنى من عدد الأحرف",

        generalCategory: "الفئة العامة",

        removeRow: "هل أنت متأكد أنك تريد حذف السجل؟",

        send: "إرسال",

        declineFinish: "رفض وإنهاء",

        finish: "إنهاء",

        noData: "لا توجد بيانات",

        createEditMenu: "إنشاء / تحرير القائمة",

        addNewBreakdown: "إضافة تفكيك جديد",

        addForm: "إضافة نموذج",

        addBottomBreak: "إضافة تفكيك سفلي",

        addNode: "إضافة عقدة",

        topCategory: "أعلى الفئة",

        explanation: "الوصف",

        browserTitle: "عنوان المستعرض",


        formTitle: "عنوان النموذج",

        addSqlColumn: "إضافة عمود SQL",

        formBg: "خلفية النموذج",

        codeEditor: "محرر الشفرة",

        authorityType: "نوع الإذن",

        viewOnly: "مشاهدة فقط",

        dark: "داكن",

        white: "أبيض",

        hightBlack: "أسود",

        run: "تشغيل",

        rowColor: "لون الصف",

        operator: "المشغل",

        flowOfForm: "تدفق النموذج",

        columnWidth: "عرض العمود",

        alignLeft: "محاذاة إلى اليسار",

        alignRight: "محاذاة إلى اليمين",

        alignButton: "محاذاة الزر",

        imageTableNameColumn: "اسم الجدول",

        uploadBg: "تحميل الخلفية",

        selectFile: "اختيار ملف",

        dropFile: "إفلات الملف هنا",

        alwaysRoundUp: "تقريب دائمًا لأعلى",

        alwaysRoundBot: "تقريب دائمًا لأسفل",

        default: "افتراضي",

        deleteOldDataTable: "حذف البيانات القديمة للجدول؟",

        deleteOldData: "حذف البيانات القديمة؟",

        integer: "عدد صحيح",

        double: "رقم عشري",

        pleaseFillFields: "يرجى ملء الحقول المطلوبة.",

        toastColumnNameNumber: "لا يمكن أن يبدأ اسم العمود برقم",

        areYouSure: "هل أنت متأكد؟",

        columnDeleted: "سيتم حذف العمود",

        allColumnDeleted: "سيتم حذف جميع الأعمدة",

        idCantStartNumber: "لا يمكن أن يبدأ المعرف برقم",

        uploadFile: "تحميل الملف",

        fileViewer: "عارض الملفات",

        ok: "حسنًا",

        map: "الخريطة",



        editPanel: "تحرير اللوحة",

        filterColumnName: "تصفية اسم العمود",

        hintExamples: "المبلغ الإجمالي، مثال: المرتب",


        columnLanguageSettings: "إعدادات لغة العمود",



        fileColumn: "عمود الملف",


        fileNameColumn: "عمود اسم الملف",

        extensionColumn: "عمود الامتداد",

        multipleSelection: "اختيار متعدد",

        addSelectionsRows: "إضافة التحديدات كصفوف",

        mapSettings: "إعدادات الخريطة",


        formatAndFeatures: "التنسيق والميزات",

        enterDateRange: "أدخل قيمة نطاق التاريخ",

        filesCanUploadedUsers: "يمكن للمستخدمين تحميل الملفات",

        barcodeColumn: "عمود الباركود",

        autoAscend: "تصاعدي تلقائيًا",

        roundType: "نوع التقريب",

        font: "الخط",

        columnProcess: "عملية العمود",

        exportColumnTotal: "تصدير إجمالي العمود",

        outsideElementDisabled: "تعطيل العناصر الخارجية",

        chat: "الدردشة",

        languages: "اللغات",

        display: "الصورة",

        enterYourComment: "أدخل تعليقك",

        comment: "التعليقات",

        fileUploaded: "تم تحميل الملفات",

        pdfViewIsButton: "عرض كزر",

        takeScreenshot: "التقاط لقطة شاشة",

        alertText: "نص التنبيه",

        clearButtonVisible: "إظهار زر المسح",

        allText: "الكل",

        letter: "الحروف فقط",

        letterandnumber: "الحروف والأرقام",

        letterandsymbol: "الحروف والرموز",

        numberandsymbol: "الأرقام والرموز",

        showIDColumn: "عرض عمود المعرّف",

        showCopyButton: "عرض زر النسخ",

        showDeleteButton: "عرض زر الحذف",

        showCheckboxButton: "عرض زر الاختيار",

        autoRowHeight: "ارتفاع الصف التلقائي",

        exportImport: "استيراد / تصدير النموذج",

        export: "تصدير",

        import: "استيراد",

        toastImport: "استيراد النموذج تم بنجاح.",

        warningApproveImport: "قم بتأكيد العملية بعد إجراء التعديلات اللازمة. سيؤدي استيراد النموذج إلى إغلاق النموذج المفتوح حاليًا.",

        defaultTodaysDateWithTime: "الافتراضي هو تاريخ اليوم والوقت",

        filterVisible: "عرض تصفية العمود",


        updatetableMaxQuantity: "الحد الأقصى لكمية التحديث",

        hideQuantity: "إخفاء اختيار الكمية",

        disableQuantityTextbox: "تعطيل كتابة الكمية",

        multiplesOfQuantity: "زيادة/تخفيض ضربات الكمية",

        justnumber: "الأرقام فقط",

        paymentHourWarning: "لا يمكنك الدفع خارج ساعات الدفع.",

        addColumn: "إضافة عمود",

        mailSettings: "خيارات البريد",

        newTaskMailDetail: "نص البريد لإرساله عند تعيين مهمة جديدة",

        updateTaskMailDetail: "نص البريد لإرساله عند تحديث المهمة",

        taskOnDropMail: "نص البريد لإرساله عند نقل المهمة",

        selectSMTP: "حدد عنوان SMTP",

        taskCreator: "مُكلف بالمهمة",

        taskEndDate: "تاريخ انتهاء المهمة",

        tags: "وسوم",

        taskStartDate: "تاريخ بدء المهمة",

        taskAssigned: "الشخص المُعيّن للمهمة",

        taskDetail: "تفاصيل المهمة",

        taskSubject: "عنوان المهمة",

        taskPri: "أولوية المهمة",

        taskEdit: "تحديث المهمة",

        addTask: "إضافة مهمة",

        taskStatus: "حالة المهمة",


        excelExportSettings: "إعدادات تصدير إكسل",

        excelExportRequiredColor: "لون العمود الذي يلزم تصديره",

        allDataExcelExport: "تصدير جميع البيانات إلى إكسل",

        warningApproveImportReport: "قم بتأكيد العملية بعد إجراء التعديلات اللازمة. سيؤدي استيراد التقرير إلى إغلاق التقرير المفتوح حاليًا.",

        reportName: "اسم التقرير",

        old: "قديم",


        upperNode: "عقدة عليا",

        pdfViewStaticPdf: "عرض ملف ثابت",

        actions: "الإجراءات",

        loading: "جاري التحميل",

        exportDataToExcell: "تصدير جميع البيانات إلى Excel",

        showRefreshIcon: "زر التحديث",

        headerFilter: "تصفية العنوان",

        filterRowVisible: "ظهور مربع البحث في العمود",

        password: "كلمة المرور",

        categories: "الفئات",

        pressEnter: "اضغط على Enter",

        historyColumnValue: "عمود تاريخ القيمة",

        hideMobile: "إخفاء على الهواتف المحمولة",

        align: "محاذاة",


        theme: "النمط",

        info: "معلومات",

        showTitle: "عرض العنوان",

        purple: "أرجواني",

        brown: "بني",

        yellow: "أصفر",

        turnOnComments: "فتح التعليقات",

        showContent: "عرض المحتوى",

        canBeHidden: "يمكن إخفاؤه",

        allowEdit: "السماح بالتعديل",

        allowDelete: "السماح بالحذف",
        allowAdd: "السماح بالإضافة",
        toastDeleteErrorMessage: "يتم استخدام هذا المكون في نصوص تفضيل النموذج. لا يمكن حذفه.",

        formOpeningMultiElementEvent: "حدث فتح النموذج مع العناصر المتعددة",

        resetDatagridLocalSettings: "إعادة تعيين إعدادات موقع الشبكة المحلي",
        loginP1: "يوفر River نظام ذاكرة شامل كذاكرة الشركة الخاصة بك تحتوي على معلومات مثل التجارب السابقة والنجاحات والفشل والدروس المستفادة وثقافة وقيم منظمتك أو مؤسستك يمكن استخدامها كمصدر مرجعي لاتخاذ القرارات والإجراءات المستقبلية. العمليات ومعرفة موظفيها وتجارب العملاء وتطوير المنتجات والخدمات وغيرها الكثير من المعلومات. ستوجه هذه المعلومات أهداف واستراتيجيات المؤسسة المستقبلية.",
        loginP2: "يساعد River عملك في تشغيل عمليات الأعمال بكفاءة وفعالية أكبر باستخدام هيكله المدعوم بتطبيقات الويب والجوال الطبيعية المتقدمة. كل ما تحتاج إلى الوصول إليه قريب منك كما لو كان جهاز الكمبيوتر الخاص بك أو جهاز التابلت أو الهاتف الذكي.",
        loginConnect: "الاتصال",
        loginText: "تسجيل الدخول",
        loginRightMore: "المزيد",
        loginHeaderP1: "ararararذاكرة الشركة الخاصة بك",
        loginHeaderP2: "ARARARRAR2التنقل",
        loginHeaderP3: "الطاقة الرسوميةARARARRR3",
        kanbanaddTasks: "إضافة مهمة للكانبان",
        tasksstatusDesciption: "يعرض اسم العمود الذي سيتم تعيين مهمة الكانبان إليه.",
        taskssubjectDescription: "يتيح لك إدخال عنوان مهمة الكانبان. العنوان الكانبان هو حقل مطلوب.",
        taskassignedToTask: "المجموعات المضافة إلى المهمة",
        taskassignedTaskDescription: "يتيح اختيار المجموعات التي سيتم تعيينها لمهمة الكانبان.",
        addedForTasks: "مضاف للمهمة",
        taskAssignedInfoDescription: "الأشخاص لإعلامهم بالمهمة",
        assignedInfo: "الأشخاص الذين سيتم إعلامهم",
        groupInfo: "المجموعات لإعلامها بالمهمة",
        sortEnd: "الترتيب حسب تاريخ الانتهاء",
        sender: "من",
        forward: "إعادة توجيه",
        attachments: "مرفقات",
        loginP3: "مخططات الكانبان والجانت تشارت هي أدوات مفيدة للغاية لتصور مشروع أو سير العمل. توفر تمثيلًا بصريًا لعناصر العمل وحالتها الحالية وسير العمل في مراحل مختلفة من العمل. وهذا يسهل تحديد نقاط الضيق وتحديد أولويات الوظائف وزيادة الكفاءة التشغيلية. مخططات الكانبان والجانت تشارت عملية وفعّالة لإدارة المشاريع الرشيقة وتطوير البرمجيات وعمليات التحسين المستمر الأخرى.",
        taskdetailDescription: "يتيح إدخال تفاصيل مهمة الكانبان. تفاصيل الكانبان هي حقل مطلوب.",
        taskassignedDescription: "يسمح للمستخدمين بتعيينهم لمهمة الكانبان. يجب تعيين مستخدم واحد على الأقل لمهمة الكانبان.",
        taskstartdateDescription: "يسمح باختيار تاريخ بداية مهمة الكانبان. في الكانبان، يتم اتخاذ تاريخ البداية تلقائيًا بناءً على وقت الإنشاء للمهمة.",
        taskenddateDescription: "يسمح باختيار تاريخ نهاية مهمة الكانبان. في الكانبان، يكون تاريخ نهاية المهمة تلقائيًا بعد أسبوع واحد من وقت إنشاء المهمة.",
        taskpriDescription: "يسمح باختيار حالة الأولوية لمهمة الكانبان. في الكانبان، يتم اتخاذ تاريخ الاستحقاق للمهمة تلقائيًا بعد أسبوع واحد من وقت إنشاء المهمة.",
        tagsDescription: "يتيح إضافة علامات لمهمة الكانبان. تتم فصل العلامات عن بعضها البعض عند إضافتها بفواصل.",
        outlookDescription: "عند تشغيل خيار إضافة المهمة إلى Outlook، يتم إضافة المهمة تلقائيًا إلى التقويم بتسجيل الدخول إلى حساب Outlook الخاص بك.",
        taskAssInfoDescription: "هذا هو الحقل المستخدم للأشخاص الذين لم يتم تعيينهم للمهمة ولكن تم طلب معلوماتهم. الأشخاص المضافون لإعلامهم لا يمكنهم التعامل مع المهام.",
        receiver: "المستلم",
        draft: "مسودة",
        spam: "بريد إلكتروني غير مرغوب فيه",
        filterstate: "تصفية حسب الحالة",
        checkForspelling: "التحقق من وجود أخطاء إملائية",
        emailToForward: "البريد الإلكتروني لإعادة التوجيه",
        lowImpMails: "رسائل البريد الإلكتروني المهمة منخفضة",
        normalImpMails: "رسائل البريد الإلكتروني المهمة عادية",
        highImpEmails: "رسائل البريد الإلكتروني المهمة مرتفعة",
        showReadMails: "عرض رسائل البريد الإلكتروني المقروءة",
        groupinfoDescription: "هذا هو الحقل المستخدم للمجموعات التي لم تتم تعيينها لمهمة، ولكن تم طلب معلوماتها. الأشخاص الذين ينتمون إلى المجموعات المضافة لإعلامهم لا يمكنهم التعامل مع المهام.",
        timeoutMission: "الوقت لبدء التنبيه قبل انتهاء المهمة",
        timeoutmissionDescription: "يتم استخدامه لتحديد الوقت لبدء التنبيه عندما تقترب مهمة الكانبان من وقت انتهائها.",
        taskperiodDescription: "يستخدم لتحديد وقت التذكير الدوري عندما يقترب تاريخ انتهاء مهمة الكانبان.",
        taskProgress: "تقدم المهمة",
        taskProgressDescription: "يستخدم للإشارة إلى تقدم مهمة الكانبان. يمكن عرض التقدم على الرسم البياني للجانت.",
        updatekanbanTask: "تحديث مهمة الكانبان",
        taskcreatorDescription: "يسمح Kanban بعرض مُنشئ المهمة.",
        uploadfileDescription: "يتم استخدامه لتحميل الملفات إلى مهمة الكانبان. يتم تسجيل الملفات أو الصور على DMS.",
        infoPersons: "الأشخاص لإعلامهم",
        warningtime: "وقت التحذير",
        taskPeriod: "فترة المهمة",
        taskProgresSatus: "تقدم المهمة",
        makeTask: "مُنشئ المهمة",
        addedforTasks: "مضاف للمهمة",
        outlookIntegration: "تكامل Outlook",
        loginoutlook: "تسجيل الدخول إلى تقويم Outlook",
        fetchoutlook: "استرجاع البيانات من تقويم Outlook",
        progressStatus: "حالة التقدم",
        taskColors: "ألوان المهام",
        addcalendarTask: "إضافة المهام إلى التقويم",
        updatetaskCalendar: "تحديث مهمة التقويم",
        statusComplete: "تصفية حسب حالة الإكمال",
        priorityFilter: "تصفية حسب الأولوية",
        columnFilter: "تصفية حسب العمود",
        assignPerson: "الأشخاص المُعيَّنين",
        assignGroup: "المجموعات المُعيَّنة",
        noTask: "لم يتم العثور على مهام",
        resetSort: "إعادة تعيين الترتيب",
        sortASC: "ترتيب من الألف إلى الياء",
        sortDESC: "ترتيب من الياء إلى الألف",
        sortAppointment: "ترتيب حسب تاريخ الإسناد",
        trash: "سلة المحذوفات",
        showOlderMail: "عرض البريد القديم",
        mailNotFound: "البريد غير موجود",
        sendNewMail: "إرسال بريد جديد",
        showUnreadMails: "عرض البريد غير المقروء",
        updateColumnMail: "الرسالة للاتصال عند إضافة مهمة إلى العمود",
        addTaskToOutlook: "إضافة المهمة إلى Outlook",
        enterYourMessage: "أدخل رسالتك",
        reminder: "تذكير",
        determinedToTask: "الوقت المحدد للمهمة",
        remainingTime: "الوقت المتبقي",
        hours: "ساعة",
        withoutPriority: "بدون أولوية",
        lowPri: "أولوية منخفضة",
        normalPri: "أولوية متوسطة",
        highPri: "أولوية عالية",
        showAllTasks: "عرض الكل",
        showIncompleteTasks: "عرض غير المكتملة",
        showCompeletedTasks: "عرض المكتملة",
        reportDesigner: "مصمم التقارير",
        fileManagementSystem: "نظام إدارة الملفات",
        formDesigner: "مصمم النماذج",
        boardDesigner: "مصمم اللوحة",
        themeSettings: "إعدادات الثيمات",
        social: "اجتماعي",
        profile: "ملف شخصي",
        contacts: "جهات الاتصال",
        files: "ملفات",
        pages: "صفحات",
        noResultsFound: "لم يتم العثور على نتائج",
        processes: "عمليات",
        filterByStatus: "تصفية حسب الحالة",
        filterByPri: "تصفية حسب الأولوية",
        filterByCol: "تصفية حسب العمود",
        todoAddTasks: "إضافة مهام القيام به",
        addLabel: "إدخال تصنيف",
        showWithSubTasks: "عرض المهام الفرعية",
        riverBoard: "لوحات River",
        notify: "الإشعارات",
        likedYourPost: "أحب مشاركتك",
        likedYourComment: "أحب تعليقك",
        systemNotify: "إشعارات النظام",
        readAllNotify: "قراءة كل الإشعارات",
        recommendedUsers: "الأشخاص الموصى بهم",
        suggestedPosts: "مشاركات مقترحة",
        sPostReposted: "تمت إعادة نشر المشاركة",
        shareSomething: "مشاركة شيء",
        updatePost: "تحديث المنشور",
        ifYouDoNotSelectGroup: "إذا لم تحدد مجموعة، يمكن لأي شخص رؤية منشورك",
        addComment: "إضافة تعليق",
        sendComment: "إرسال تعليق",
        areYouSureYouWantToDeleteThisPost: "هل أنت متأكد أنك تريد حذف هذا المنشور؟",
        yes: "نعم",
        no: "لا",
        deletePost: "حذف المنشور",
        postSuccesfullyDeleted: "تم حذف المنشور بنجاح",
        deleteComment: "حذف التعليق",
        areYouSureYouWantToDeleteThisComment: "هل أنت متأكد أنك تريد حذف هذا التعليق؟",
        commentSuccesfullyDeleted: "تم حذف التعليق بنجاح",
        sharePost: "مشاركة المنشور",
        areYouSureYouWantToShareThisPost: "هل أنت متأكد أنك تريد مشاركة هذا المنشور؟",
        repostedSuccessfully: "تمت إعادة نشره بنجاح",
        upload: "تحميل",
        refresh: "تحديث",
        allowedExtentions: "الامتدادات المسموح بها: JPG، GIF أو PNG. الحد الأقصى لحجم الملف",
        systemLandingPagePreference: "تفضيلات صفحة الهبوط للنظام",
        allownonadminuserstosearchforotherusers: "السماح للمستخدمين غير الإداريين بالبحث عن مستخدمين آخرين",
        passwordChange: "تغيير كلمة المرور",
        newPassword: "كلمة المرور الجديدة",
        retypethenewpassword: "أعِد كتابة كلمة المرور الجديدة",
        notifications: "الإشعارات",
        showWhoLikesYourPost: "عرض من يحب منشوراتك",
        showWhoLikesYourComment: "عرض من يحب تعليقاتك",
        aboutMe: "عني",
        bio: "سيرتي الذاتية",
        dateOfBirth: "تاريخ الميلاد",
        website: "موقع الويب",
        phone: "الهاتف",
        saveChanges: "حفظ التغييرات",
        username: "اسم المستخدم",
        content: "المحتوى",
        projectManagement: "إدارة المشاريع",
        addPage: "إضافة صفحة",
        addFolder: "إضافة مجلد",
        showUsers: "المستخدمون الذين يمكنهم رؤية",
        showGroups: "المجموعات التي يمكنها رؤية",
        readonlyUsers: "المستخدمون الذين يمكنهم القراءة",
        readonlyGroups: "المجموعات التي يمكنها القراءة",
        columnHeight: "ارتفاع العمود",
        showKanbanCount: "عرض معلومات الكمية في الكانبان",
        kanbanSettings: "إعدادات الكانبان",
        gantSettings: "إعدادات الجانت",
        schedulerSettings: "إعدادات التقويم",
        showRowLines: "عرض خطوط الصفوف",
        activateOutlookConnection: "تفعيل اتصال Outlook",
        selectOutlookColumn: "حدد عمود Outlook",
        selectSMTPopoverText: "تم اختيار SMTP للاستخدام في مكونات الكانبان والجانت والتقويم أو المهام القادمة.",
        menuFilePathId: "معرف مجلد قائمة River",
        recycleBinPathName: "اسم مجلد سلة المحذوفات في River",
        recycleBinPathId: "معرف مجلد سلة المحذوفات في River",
        fileNameChangedMessage: "تم تغيير اسم الملف بنجاح.",
        fileNameChangedErrorMessage: "تعذر تغيير اسم الملف.",
        fileCreatedMessage: "تم إنشاء الملف بنجاح.",
        newTaskMailDetailPopoverText: "عند تعيين مهمة جديدة في مكونات الكانبان والجانت والتقويم أو المهام القادمة، يتم إضافة جزء من محتوى البريد إلى البريد الآلي الذي سيتم إرساله إلى المستخدمين المعينين.",
        taskOnDropMailPopoverText: "جزء من محتوى البريد الذي سيتم إضافته إلى البريد الآلي الذي سيتم إرساله إلى مكونات الكانبان والجانت والتقويم أو المهام القادمة عند تغيير العمود الذي يحتوي على مهمة.",
        createdBy: "المُنشئ",
        updatedBy: "تم التحديث بواسطة",
        createdDate: "تاريخ الإنشاء",
        updatedDate: "تاريخ التحديث",
        folderPermissionSettings: "إعدادات ترخيص الملف",
        quantity: "قطعة",
        fileManagementPermissionSettings: "إعدادات ترخيص إدارة الملفات",
        fileNotFoundErrorText: "لم يتم العثور على ملفات تطابق معايير البحث الخاصة بك.",
        updateTaskMailDetailPopoverText: "جزء من محتوى البريد الذي سيتم إضافته إلى البريد الآلي الذي سيتم إرساله إلى المستخدمين المعينين عند إجراء تغييرات على مكونات الكانبان والجانت والتقويم أو المهام ذات الصلة.",
        selectOutlookColumnPopoverText: "تم اختيار العمود الذي سيتم استخدامه لتضمين المهام التي سيتم إضافتها عبر Outlook.",
        createFolder: "إنشاء مجلد",
        versionHistory: "سجل الإصدارات",
        deletePermanently: "حذف نهائيًا",
        recover: "استرجاع",
        groupPermissions: "أذونات المجموعة",
        allowUpload: "السماح بتحميل الملفات",
        allowView: "السماح بعرض الملفات",
        allowDownload: "السماح بتنزيل الملفات",
        permissionType: "نوع الإذن",
        applyToSubFolders: "تطبيق على جميع المجلدات الفرعية",
        newFolderName: "اسم الملف الجديد",
        tagTips: "يجب أن يبدأ الوسم برمز #. يمكن أن يحتوي على أرقام وأحرف فقط",
        addAsNewVersion: "إضافة كإصدار جديد",
        addAndChangeName: "تحميل مع إعادة التسمية",
        skip: "تخطي",
        fileExistError: "يوجد ملف بنفس الاسم في المجلد الهدف",
        processFileShow: "عرض مجلد عملية River",
        processFilePathName: "اسم مجلد عملية River",
        processFilePathId: "معرف مجلد عملية River",
        menuFileShow: "عرض مجلد قائمة River",
        menuFilePathName: "اسم مجلد قائمة River",
        fileCreatedErrorMessage: "فشل إنشاء الملف.",
        fileDeletedMessage: "تم حذف الملف بنجاح.",
        fileDeletedErrorMessage: "فشل حذف الملف.",
        fileMovedMessage: "تم نقل الملف بنجاح.",
        fileMovedErrorMessage: "فشل نقل الملف.",
        fileCopyMessage: "تم نسخ الملف بنجاح.",
        fileCopyErrorMessage: "فشل نسخ الملف.",
        fileDownloadMessage: "تم تنزيل الملف بنجاح.",
        fileDownloadErrorMessage: "فشل تنزيل الملف.",
        fileSizeShowErrorMessage: "لا يمكن عرضه بسبب حجم الملف الكبير. يرجى تنزيل الملف",
        unsupportedFile: "الملف غير مدعوم",
        fileNameError: "هناك ملف بنفس اسم الملف، يرجى المحاولة مرة أخرى باستخدام اسم مختلف",
        fileGivePermissionMessage: "تم إعطاء إذن الملف بنجاح.",
        fileGivePermissionErrorMessage: "خطأ في منح إذن الملف. فشل التسجيل.",
        groupName: "اسم المجموعة",
        createGroup: "إنشاء مجموعة",
        conversation: "المحادثات",
        startConversation: "بدء المحادثة",
        userNotFound: "المستخدم غير موجود",
        chatNotFound: "الدردشة غير موجودة",
        themeTypeLight: "فاتح",
        themeTypeBorderedLight: "محدودة فاتحة",
        themeHalfDark: "نصف داكنة",
        themeDark: "داكنة",
        themeRouteAnimation: "تحريك الانتقال",
        themeRouteAnimationFadeInLeft: "ظهور من اليسار",
        themeRouteAnimationZoomIn: "تكبير",
        themeRouteAnimationFadeIn: "ظهور",
        themeRouteAnimationNone: "لا شيء",
        themeMenuType: "نوع القائمة",
        themeMenuTypeHorizontal: "أفقي",
        themeMenuTypeVertical: "عمودي",
        themeMenuCollapse: "تصغير القائمة",
        themeNavbarColor: "لون شريط التنقل",
        themeNavbarType: "نوع شريط التنقل",
        themeSticky: "ثابت",
        themeStatic: "ثابت",
        themeFloating: "عائم",
        themeHidden: "مخفي",
        themeFooterType: "نوع الهامش السفلي",
        catalog: "الكتالوج",
        riverBoards: "لوحات River",
        clipboardDesigner: "مُصمم الحافظة",
        designMode: "أنت في وضع التصميم",
        editPage: "تعديل الصفحة",
        rename: "إعادة التسمية",
        exportToPdf: "تصدير إلى PDF",
        exportToExcel: "تصدير إلى Excel",
        revenueReport: "تقرير الإيرادات",
        header: "العنوان",
        connection: "الاتصال",
        primaryKey: "المفتاح الأساسي",
        secondaryKey: "المفتاح الثانوي",
        completedKey: "المفتاح للمهام المكتملة",
        countKey: "المفتاح للعد",
        statusKey: "المفتاح للحالة",
        progressConnection: "اتصال التقدم",
        progressScript: "نص السيناريو للتقدم",
        progressPrimaryKey: "المفتاح الأساسي للتقدم",
        progressCountKey: "المفتاح لعد التقدم",
        progressPercentageKey: "المفتاح لنسبة التقدم",
        progressColorKey: "المفتاح لللون في التقدم",
        keyColumn: "عمود القيمة",
        currency: "العملة",
        whitePage: "صفحة بيضاء",
        noReportSelected: "لم يتم اختيار تقرير",
        noTypeSelected: "لم يتم اختيار نوع التقرير",
        whitePageReports: "تقرير الصفحة البيضاء",
        addBranch: "إضافة فرع/فرع فرعي",
        branchName: "اسم الفرع",
        rowNumber: "رقم التسلسل",
        reportTypeSelect: "اختر نوع التقرير",
        reportDescription: "وصف التقرير...",
        useWeb: "استخدم على الويب",
        useMobile: "استخدم على الجوال",
        mainScript: "السيناريو الرئيسي",
        prerunScript: "تشغيل سيناريو مسبق؟",
        preScript: "السيناريو المسبق",
        testIt: "اختبار",
        selectAll: "تحديد الكل",
        addReport: "إضافة تقرير",
        selectReportType: "اختر نوع التقرير",
        reportType: "نوع التقرير",
        reportDesingPermission: "المستخدم ليس لديه أذونات تصميم التقرير.",
        reportImportedOpenLeftMenu: "تم استيراد التقرير. يمكنك فتحه من القائمة اليسرى.",
        forReportExport: "لتصدير التقرير، يجب أولاً تحديد تقرير.",
        anErrorOccurred: "حدث خطأ غير متوقع",
        reportDeleted: "تم حذف التقرير",
        branchDeleted: "تم حذف الفرع",
        addBranchName: "يرجى إدخال اسم الفرع",
        addedBranch: "تمت إضافة الفرع",
        selectConnectionAddress: "يرجى اختيار عنوان الاتصال",
        enterSqlScript: "يرجى إدخال نص SQL",
        enterReportName: "يرجى إدخال اسم التقرير",
        reportAdded: "تمت إضافة التقرير",
        numberOfDataToBeProcessed: "تم الاتصال بنجاح. عدد البيانات التي يتعين معالجتها:",
        dataNotFoundCheckYourConnection: "لم يتم العثور على بيانات! يرجى التحقق من الاتصال وأمر SQL الخاص بك",
        connectionNotFoundCheckYourConnection: "فشل الاتصال! يرجى التحقق من الاتصال وأمر SQL الخاص بك",
        fillConnectionAndSql: "يرجى ملء معلومات الاتصال وأمر SQL الخاص بك",
        allTypesActivate: "تفعيل جميع الأنواع عند وقت العرض",
        passwordRequired: "كلمة المرور مطلوبة",
        showLess: "إظهار أقل",
        showMore: "إظهار المزيد",
        authority: "السلطة",
        preScriptDescription: "وصف السيناريو المسبق",
        enterReportFilters: "أدخل عوامل التصفية للتقرير",
        gridDesigner: "مُصمم الجداول",
        addMainBranch: "إضافة فرع رئيسي",
        resetSettings: "إعادة تعيين الإعدادات",
        columsToGroup: "استخدم قائمة السياق في عمود العنوان لتجميع البيانات",
        customize: "تخصيص",
        colorType: "نوع اللون",
        formatManagement: "إدارة التنسيق",
        bottomTotalColumn: "عمود المجموع السفلي",
        formatType: "نوع التنسيق",
        datagridSettingsReset: "سيتم إعادة تعيين إعدادات جدول البيانات",
        unexpectedError: "عذرًا، حدث خطأ غير متوقع.",
        changesSaved: "تم حفظ التغييرات",
        addGantTask: "إضافة مهمة جانت",
        updGantTask: "تحديث مهمة جانت",
        updSchedulerTask: "تحديث مهمة التقويم",
        updTodoTask: "تحديث مهمة القائمة",
        completeTask: "اكتمال المهمة",
        cantBeEmptyKanban: "عنوان المهمة وتفاصيل المهمة ومكلف المهمة لا يمكن أن يكونوا فارغين",
        successAdd: "تمت إضافة المهمة بنجاح",
        successUpd: "تم تحديث المهمة بنجاح",
        statusChangeTodo: "تم تغيير حالة اكتمال المهمة بنجاح.",
        reminderSended: "تم إرسال بريد التذكير بالمهمة بنجاح",
        askForCompleteTask: "هل أنت متأكد أنك تريد اكتمال المهمة؟",
        infoForCompletedTask: "ستتم أيضًا إكمال المهام الفرعية للمهمة.",
        noPermisison: "ليس لديك إذن.",
        successDependencyAdded: "تمت إضافة الاعتمادية للمهمة بنجاح",
        filterimportant: "تصفية حسب الأهمية",
        enterEmail: "أدخل عنوان البريد الإلكتروني",
        reply: "الرد",
        newCol: "عمود جديد",
        thickness: "السماكة",
        distanceToUpperComponent: "المسافة إلى المكون العلوي",
        companyName: "اسم الشركة",
        showEmailModule: "عرض وحدة البريد الإلكتروني",
        distanceToBottomComponent: "المسافة من المكون السفلي",
        canNonAdminUsersPost: "هل يمكن للمستخدمين غير الإداريين نشر المشاركات",
        columnColor: "لون العمود",
        byWho: "بواسطة",
        updated: "تم التحديث",
        commentAdded: "تمت إضافة التعليق",
        completed: "تم",
        addRemoveColumns: "إضافة/إزالة الأعمدة",
        searchMessage: "البحث في الرسائل",
        riverReports: "تقارير River BI",
        forms: "نماذج",
        searchinforms: "البحث في النماذج",
        searchVoiceMessage: "الرسالة التي تبحث عنها موجودة في ملف الصوت هذا",
        searchVoiceMessages: "جاري البحث في رسائل الصوت",
        showSocialNotifications: "عرض إشعارات الشبكات الاجتماعية",
        showProjectManagementNotifications: "عرض إشعارات إدارة المشاريع",
        showProcessNotifications: "عرض إشعارات العمليات",
        pageDesingPermission: "المستخدم ليس لديه أذونات تصميم الصفحة.",
        filemanagementDesingPermission: "المستخدم ليس لديه أذونات إدارة الملفات.",
        taskDelete: "سيتم حذف المهمة.",
        tasksWillDeleted: "هل أنت متأكد أنك تريد حذف المهمة؟",
        componentSettings: "إعدادات المكون",
        aboutComponent: "حول المكون",
        sureSaveChanges: "هل أنت متأكد أنك تريد حفظ التغييرات؟",
        showDeletedNotifications: "عرض الإشعارات التي حذفتها",
        shareSometthing: "مشاركة شيء",
        resetPageLayout: "إعادة تعيين تخطيط الصفحة",
        downloadingSS: "جاري تنزيل لقطة الشاشة. يرجى الانتظار.",
        showHorizontalLines: "عرض الخطوط الأفقية",
        turnOnVerticalHide: "تشغيل إخفاء الخطوط العمودية",
        whichHorizontalLinelinesAreNotVisible: "الخط الأفقي الذي لا يتم عرض الخطوط به",
        priority: "أولوية",
        desingMode: "أنت في وضع التصميم",
        boardSidebarMessageField: "حقل رسالة المهمة",
        boardSidebarExtraField: "حقل مكون إضافي للمهمة",
        boardSidebarTaskHistory: "تاريخ المهمة",
        loadMoreNotifications: "عرض المزيد من الإشعارات",
        deletedNotifications: "الإشعارات المحذوفة",
        noFormNotification: "لا توجد إشعارات للعمليات بعد",
        noDeletedFormNotifications: "لا توجد إشعارات للعمليات المحذوفة",
        noDeletedBoardNotification: "لا توجد إشعارات للوحات المحذوفة",
        noBoardNotification: "لا توجد إشعارات للوحات بعد",
        noDeletedSocialNotification: "لا توجد إشعارات لوسائل التواصل المحذوفة",
        noSocialNotification: "لا توجد إشعارات لوسائل التواصل بعد",
        deleteAll: "حذف الكل",
        markAllRead: "وضع علامة قراءة على الكل",
        board: "لوحة",
        form: "نموذج",
        formNotifications: "إشعارات النموذج",
        boardNotifications: "إشعارات اللوحة",
        socialNotifications: "إشعارات الشبكات الاجتماعية",
        email: "البريد الإلكتروني",
        oldFormName: "اسم النموذج القديم",
        newFormName: "اسم النموذج الجديد",
        oldFormVariable: "متغير النموذج القديم",
        newFormVariable: "متغير النموذج الجديد",
        oldCategory: "الفئة القديمة",
        newCategory: "الفئة الجديدة",
        connectionInFile: "الاتصال في الملف",
        yourConnection: "اتصالك",
        element: "عنصر",
        script: "سكريبت",
        formClosingEvent: "حدث إغلاق النموذج",
        message: "رسالة",
        formExportTextError: "لا توجد مكونات مضافة إلى النموذج أو النموذج غير محفوظ. يرجى تصحيح هذه المشكلة لتجنب فشل العملية.",
        checkRequiedFields: "فحص الحقول المطلوبة",
        dontCheckRequiredFields: "عدم فحص الحقول المطلوبة",
        createPdfWarning: "إذا تم التحقق منه، لن يتم حفظ النموذج وإغلاقه",
        cratePdf: "إنشاء PDF",
        identityNumber: "رقم الهوية",
        minPaymentHour: "الحد الأدنى للساعة",
        maxPaymentHour: "الحد الأقصى للساعة",
        darkBlue: "أزرق داكن",
        orange: "برتقالي",
        component: "مكون",
        properties: "خصائص",
        dmsPath: "مسار DMS",
        dashboard: "لوحة التحكم",
        slider: "شريط التمرير",
        list: "قائمة",
        otherImages: "صور أخرى",
        unitScript: "سكريبت الوحدة",
        updatableMaxQuantity: "الكمية القصوى القابلة للتحديث",
        newPage: "صفحة جديدة",
        code: "الرمز",
        resetHiddenComponent: "إعادة تعيين قيمة المكونات المستهدفة المخفية",
        bold: "عريض",
        italic: "مائل",
        stringFormat: "تنسيق السلسلة",
        videoUploaded: "تم تحميل الفيديو",
        fillInAllFields: "املأ جميع الحقول",
        normalPaging: "تصفح عادي",
        pageSize: "حجم الصفحة",
        special: "خاص",
        link: "رابط",
        fontWeight: "وزن الخط",
        pdfFileNotFound: "الملف PDF غير موجود",
        themeMenuHidden: "القائمة المخفية",
        likedOn: "أحب على",
        addedTask: "مهمة مضافة",
        updatedTask: "تم تحديث المهمة",
        messageAddedToTask: "تمت إضافة الرسالة إلى المهمة",
        deleteTask: "تم حذف المهمة",
        completedTask: "تم إنجاز المهمة",
        subCategory: "الفئة الفرعية",
        toTheBoard: "إلى السبورة",
        hideTaskDetail: "إخفاء تفاصيل المهمة",
        selectData: "اختر البيانات",
        noContentYet: "لا يوجد محتوى بعد",
        addRowBetween: "إضافة صف بينهما",
        userPermissions: "صلاحيات المستخدم",
        riverModules: "RIVER وحدات النهر",
        processHistory: "تاريخ العملية",
        commentHasBeenMadeAtThisStage: "تمت إضافة التعليق في هذه المرحلة",
        askForPassword: "هل ترغب في طلب كلمة مرور؟",
        passwordIsWrong: "كلمة المرور غير صحيحة، يرجى المحاولة مرة أخرى.",
        pleaseEnterPassword: "الرجاء إدخال كلمة المرور للمتابعة.",
        onePaymentEachSave: "للدفع مرة واحدة لكل حفظ",
        clearSelection: "مسح التحديد",
        allowDownloadVersionHistoryDocuments: "السماح بتنزيل وثائق تاريخ الإصدار",

        delegated: "مفوض",
        openDocxToPdf: "فتح الملف كملف PDF",
        activationStartDate: "تاريخ بدء التنشيط",
        activationEndDate: "تاريخ انتهاء التنشيط",
        changeActivationDate: "تغيير تاريخ التنشيط",
        showArchive: "عرض الأرشيف",
        hideArchive: "إخفاء الأرشيف",

        jobListMessageArea: "منطقة رسائل قائمة الوظائف",
        tasks: "المهام",
        maxFileCountLimit: "الحد الأقصى لعدد الملفات",
        fileCountLimit: "الحد الأقصى لعدد الملفات",
        numberOfFilesExceeded: "تم تجاوز عدد الملفات",
        numberOfFilesLimit: "حد العدد للملفات"
    }

}